<template>
    <div id="app">
        <div class="app-container" ref="appContainer">
            <div class="menu-container" :class="hidden">
                <Menu />
            </div>
            <div class="main-container">
                <Header v-if="$route.path !== '/login'" />
                <router-view></router-view>
            </div>
        </div>
        <SvgSymbols v-show="false" />
    </div>
</template>

<script setup>
import { RouterView } from 'vue-router';
import Menu from './components/Menu.vue';
import Header from './components/Header.vue';
import SvgSymbols from './components/SvgSymbols.vue';
import dayjs from 'dayjs';
import WorkerService from './services/workerService';
import ShopService from './services/shopService';
import GroupService from './services/groupService';
import ScheduleService from './services/scheduleService';

const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

</script>

<script>

export default {
    name: 'Informitalia-app',
    components: {
        Menu,
        RouterView,
        Header,
        SvgSymbols,
    },
    data() {
        return {
            options: {
                start: dayjs().format('YYYY-mm-dd')
            },
            route: this.$route,
            hidden: ''
        }
    },
    watch: {
        $route(value) {
            console.log(value.path)
            if (!localStorage.getItem('token')) {
                if (value.path !== '/' || value.path !== '/login') {
                    this.$router.push('/login')

                }
            }

            if (value.path == '/login') {
                this.hidden = ' hidden'
            } else {
                this.hidden = ''
            }
        }
    },
    methods: {
        getExpiration() {
            if (localStorage.getItem('token') !== null) {
                var expiration = localStorage.getItem('expiry')
                if (dayjs().unix() > parseInt(expiration)) {
                    localStorage.clear()
                    this.$router.go()
                }
            }
        }
    },
    async mounted() {
        console.log(process.env.NODE_ENV)

        if (localStorage.getItem('token') == null) {
            this.$router.push('/login')
        }

        if (window.innerWidth > 768) {
            this.$refs.appContainer.classList.add('menu-open')
        }

        this.getExpiration()

        if (localStorage.getItem('token')) {
            if (localStorage.getItem('shopsList') == null) localStorage.setItem('shopsList', JSON.stringify(await ShopService.getShops(localStorage.getItem('token'))));
            if (localStorage.getItem('workersList') == null) localStorage.setItem('workersList', JSON.stringify(await WorkerService.getWorkers(localStorage.getItem('token'))));
            if (localStorage.getItem('groupsList') == null) localStorage.setItem('groupsList', JSON.stringify(await GroupService.getGroups(localStorage.getItem('token'))));
            if (localStorage.getItem('schedulesList') == null) localStorage.setItem('schedulesList', JSON.stringify(await ScheduleService.getAllSchedules(localStorage.getItem('token'))));
        }
    }
}

</script>