<template>
    <div v-if="!deleted" class="textarea-container">
        <div ref="notice" class="notice"></div>
        <div class="icons-container">
            <div v-if="save && visible" class="icon-container" @click="$emit('saveNote'), save = false, edit = true">
                <svg class="icon icon--save">
                    <use xlink:href="#save"></use>
                </svg>
            </div>
            <div v-if="edit && visible" class="icon-container" @click="$emit('editNote')">
                <svg class="icon icon--edit">
                    <use xlink:href="#edit-square"></use>
                </svg>
            </div>
            <div v-if="edit && visible" class="icon-container" @click="$emit('deleteNote'), deleted = true">
                <svg class="icon icon--delete">
                    <use xlink:href="#delete"></use>
                </svg>
            </div>
        </div>
        <textarea :name="name" :class="[inputState, noIcon]" :disabled="disabled" :placeholder="placeholder" :value="value" @input="$emit('update:value', $event.target.value)"></textarea>
    </div>
</template>

<script>

export default {
    name : 'form-notes',
    props : {
        name : {
            default : '',
            type : String
        },
        inputState : {
            default : '',
            type : String
        },
        disabled : {
            default : false,
            type : Boolean
        },
        icon : {
            default : '',
            type : String
        },
        placeholder : {
            default : '',
            type : String
        },
        value : {
            default : '',
            type : String
        },
        icons : {
            default : 2,
            type : Number
        },
        visible : {
            default : true,
            type : Boolean
        }

    },
    data(){
        return{
            deleted: false
        }
    },
    computed : {
        iconId(){
            return '#' + this.icon
        },
        iconClass(){
            return 'icon icon-' + this.icon
        },
        noIcon(){
            if(this.icon == ''){
                return ' no-icon'
            }else{
                return ''
            }
        },
        save(){
            if(this.icons == 2) return true; else return false
        },
        edit(){
            if(this.icons == 1) return true; else return false
        }
    },
    emits : ['update:value']
}

</script>