<template>
    <div class="section section--detail">
        <div class="detail-container">
            <div class="detail-main-container">
                <SalesPointsDetailForm ref="salesPointsForm" :options="options" :notes="notes" :edit="edit" :listEdit="edit"/>
                <!-- <QRcodeGenerator :shop_options="options" /> -->
                <EditButtons v-if="edit" toUpdate="Aggiorna punto vendita" @cancel="editsCancel" resetText="Annulla modifiche" @validate="validate()" />
            </div>
            <div class="detail-aside-container">
                <AsideDetail @edit="salesPointsEdit" @archive="salesPointsArchive" @delete="salesPointsDelete" @active="active" :edit="edit" :status="options.status" subject="punto vendita"/>
            </div>
        </div>
        <ProfileRemover v-if="erase" text="Elimina punto vendita" toDelete="il punto vendita" @cancel="salesPointsDelete"/>
        <Modal v-if="archive" title="Punto vendita archiviato" text="Punto vendita archiviato con successo" @redirect="redirect"/>
        <Modal v-if="updated" title="Punto vendita aggiornato" text="Punto vendita aggiornato con successo" @redirect="redirect"/>
        <Modal v-if="reactivate" title="Punto vendita ripristinato" text="Punto vendita ripristinato con successo" @redirect="redirect"/>
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrolla i dati o riprova più tardi" @redirect="redirect" />
    </div>
</template>

<script>
import ProfileRemover from './tools/ProfileRemover.vue';
import AsideDetail from './AsideDetail.vue';
import EditButtons from './tools/EditButtons.vue';
import SalesPointsDetailForm from './forms/SalesPointsDetailForm.vue'
import ShopService from '../services/shopService';
import { setShop } from '../setters';
import Modal from '../components/tools/Modal.vue';
// import QRcodeGenerator from '../components/QRcodeGenerator.vue'


export default {
    name: "sales-points-detail",
    components: { 
        ProfileRemover,
        AsideDetail,
        EditButtons,
        SalesPointsDetailForm,
        Modal,
        // QRcodeGenerator
     },
     props : {
        options : {
            default : null,
            type : Object
        },
        notes : {
            default: null,
            type: Array
        },
        listEdit : {
            default : null,
            type : Boolean
        }
    },
    data(){
        return{
            archive : null,
            edit: (this.listEdit) ? true : false,
            erase : null,
            error: false,
            reactivate: false,
            updated: false,
        }
    },
    methods : {
        async salesPointsArchive(bool){

            this.archive = bool

            var shopData = setShop(this.options, 'archiviato', this.options.inspector_name)

            const Shop = await ShopService.updateShop(localStorage.getItem('token'), shopData, this.options.id)

            if(Shop !== false){

                var shopsList = JSON.parse(localStorage.getItem('shopsList'))

                Array.from(shopsList).map((shop) => {

                    if(this.options.id == shop.id){
                        shop.status = "archiviato"
                    }
                })

                localStorage.setItem('shopsList', JSON.stringify(shopsList))
            }else{
                this.error = true
            }
        },
        async active(bool){

            this.reactivate = bool

            var shopData = setShop(this.options, 'attivo')

            const Shop = await ShopService.updateShop(localStorage.getItem('token'), shopData, this.options.id)

            if(Shop !== false){

                var shopsList = JSON.parse(localStorage.getItem('shopsList'))

                Array.from(shopsList).map((shop) => {
                    
                    if(this.options.id == shop.id){
                        shop.status = "attivo"
                    }
                })

                localStorage.setItem('shopsList', JSON.stringify(shopsList))
            }else{
                this.error = true
            }
        },
        salesPointsEdit(bool){
            this.edit = bool
        },
        salesPointsDelete(bool){
            this.erase = bool
        },
        editsCancel(bool){
            this.edit = bool
            this.$router.go()
        },
        async validate() {

            const salesPointsForm = this.$refs.salesPointsForm
            const isFormValid = salesPointsForm.validate()

            if(isFormValid && !salesPointsForm.v$.$error){

                this.edit = false
                this.updated = true

                var shopData = setShop(salesPointsForm, 'attivo')

                const Shop = await ShopService.updateShop(localStorage.getItem('token'), shopData, this.options.id)

                if(Shop !== false){

                    var shopsList = JSON.parse(localStorage.getItem('shopsList'))
                
                    Array.from(shopsList).map((shop) => {

                        if(this.options.id == shop.id){
                            shop = setShop(salesPointsForm, 'attivo')
                        }
                    })

                    localStorage.setItem('shopsList', JSON.stringify(shopsList))
                }else{
                    this.error = true
                }

            }else{

                return
            }
        },
        redirect(){
            this.archive = false
            this.updated = false
            this.reactivate = false
            this.$router.go()
        }
    }
}

</script>