<template>
    <div class="heading">
        <div class="corner"></div>
        <div v-for="(day, index) in weekDays" :key="index" class="day">
            <div class="dddd">{{ day[0] }}</div> <div class="dd-mm-yyyy">{{ day[1] }}</div>
        </div>        
    </div>
</template>

<script setup>
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
dayjs.extend(dayOfYear)
require('dayjs/locale/it')

// import scheduleList from '@/models/shifts'
</script>

<script>

export default {
    name: 'Scheduler-Header',
    data(){
        return{
            scrollTop: 0,
            scrollLeft: 0,
        }
    },
    props : {
        date : {
            default : new Date(),
            type : Date
        }
    },
    watch: {
        scrollLeft(value){
            document.querySelector('.heading').style.transform = `translateX(${value}px)`
        },
        scrollTop(value){
            document.querySelector('.corner').style.transform = `translateY(${value}px)`
        }
    },
    computed:{

        weekDays(){
            var weekDays = [];

            if(new Date(this.date).getDay() !== 1 ){
                for(var i = 0; i < 7; i++){
                    weekDays.push([dayjs(new Date(this.date).setDate(new Date(this.date).getDate() - (new Date(this.date).getDay() - 1) + i)).locale('it').format('dddd'), dayjs(new Date(this.date).setDate(new Date(this.date).getDate() - (new Date(this.date).getDay() - 1) + i)).format('DD-MM-YYYY')])
                }
            }else{
                for(i = 0; i < 7; i++){
                    var day = new Date(this.date).setDate(new Date(this.date).getDate() + i)
                    weekDays.push([dayjs(day).locale('it').format('dddd'), dayjs(day).format('DD-MM-YYYY')])
                }
            }

            return weekDays
        }
    },
    mounted() {
        document.querySelector('.scheduler').addEventListener('scroll', (el) => {
            this.scrollTop = el.target.scrollTop
            this.scrollLeft = el.target.scrollLeft
        })
    }
}

</script>