<template>
    <div class="main">
        <PageTitle @back="back"/>
        <div class="window" :style="{'padding': padding}">

            <FormSelect v-if="!visible && !edit" class="status-select" labelText="Filtra" :item-list="statusList" v-model:value="status" />

            <Scheduler v-if="visible" :home="editable" :updated-schedule-list="scheduleList" :updated="updated" 
            :scheduleDate="shifts.date_start" :scheduleId="shifts.id" :weekNumber="shifts.week_number" :year="shifts.year"
            @back="backToList" @schedule="toSchedule" @archive="draftShift" @verify="verifyConstraints" :block="editable" @shopVerify="shopVerifyConstraints" 
            :group_id="shifts.group_id" :status-editable="statusEditable" @daysOff="openDaysOff" />

            <CreateShiftsForm v-if="edit" @create-shift="editShift" :shopsList="shops" :weekChoice="false" :scheduleId="shifts.id" :group_id="shifts.group_id"/>
            <List v-if="!visible && !edit" :item-list="shiftsList" @edit="shiftsEdit" @delete="shiftsDelete" @archive="shiftsArchive" @visible="shiftsVisible" @active="scheduleShift"/>

        </div>
        <Modal v-if="drafted" title="Turno salvato in bozza" text="Turno salvato in bozza con successo" @redirect="draft"/>
        <Modal v-if="archive" title="Turno archiviato" text="Turno archiviato con successo" @redirect="archiveShift"/>
        <Modal v-if="reactivate" title="Turno programmato" text="Turno programmato con successo" @redirect="schedule"/>
        <Modal v-if="daysOffError" title="Riposi" :text="errorMessage" @redirect="closeDaysOff"/>
        <Modal v-if="daysOffOk" title="Riposi aggiornati" text="Riposi aggiornati correttamente" @redirect="close"/>
        <Constraints v-if="verify" :year="shifts.year" :week_number="shifts.week_number" :group_id="shifts.group_id" @close="close"/> 
        <ShopConstraints v-if="shopVerify" :year="shifts.year" :week_number="shifts.week_number" :group_id="shifts.group_id" :scheduleId="shifts.id" @close="closeShops"/> 
        <DaysOffModal v-if="daysOff" :week="shifts.week_number" :year="shifts.year" :update="updated" @close="close" @saveDaysOff="updateDaysOff"
                            :days-off-date="daysOffDate" :user_id="user_id" />
    </div>
</template>

<script setup>

import Constraints from '../../components/Constraints.vue';
import CreateShiftsForm from '../../components/forms/CreateShiftsForm.vue';
import DaysOffModal from '../../components/days-off/DaysOffModal.vue';
import DaysOffService from '../../services/daysOffService';
import FormSelect from '../../components/tools/FormSelect.vue';
import List from '../../components/shifts/List.vue';
import Modal from '../../components/tools/Modal.vue';
import PageTitle from '../../components/PageTitle.vue';
import ScheduleService from '../../services/scheduleService';
import Scheduler from '../../components/Scheduler.vue';
import ShiftsObjects from '../../services/shiftsObjects';
import ShopConstraints from '../../components/ShopConstraints.vue';
import StorePlanningService from '../../services/storePlanningService';

import { daysOffErrorHandler } from '../../dataService';
import dayjs from 'dayjs';

const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

</script>

<script>

export default {
    name: 'shifts-list',
    components: {
        // ShiftsDetail,
        List,
        PageTitle,
        Scheduler,
        CreateShiftsForm,
        Modal,
        Constraints,
        ShopConstraints
    },
    data() {
        return {
            archive: false,
            daysOff: false,
            daysOffDate: '',
            daysOffError: false,
            daysOffOk: false,
            delete: false,
            drafted: false,
            edit: false,
            editable: false,
            errorMessage: true,
            // local: false,
            reactivate: false,
            scheduleList : null,
            shifts: null,
            shiftsList : [],
            shops: [],
            shopVerify: false,
            status: 'pianificato',
            statusEditable: true,
            toVisit: false,
            updated: false,
            user_id: null,
            verify: false,
            visible: false,
        }
    },
    methods: {
        shiftsDelete(bool, item) {
            this.delete = bool
            this.shifts = item
        },
        async shiftsEdit(bool, item) {

            this.edit = bool
            this.shifts = item
            this.toVisit = false 

            document.querySelector('.page-title').innerHTML = 'Modifica turno'
            document.querySelector('.back-arrow').style.visibility = 'visible'
            
            this.shops = await StorePlanningService.getShopsIdArray(
                localStorage.getItem('token'), 
                this.shifts.year,
                this.shifts.week_number, 
                this.shifts.group_id
            )
        },
        shiftsArchive(bool, item) {
            this.archive = bool
            this.shifts = item
        },
        async shiftsVisible(bool, item) {
            this.visible = bool
            this.toVisit = true
            this.shifts = item

            this.scheduleList = await ShiftsObjects.getShiftObject(
                localStorage.getItem('token'), 
                this.shifts.year, 
                this.shifts.week_number, 
                this.shifts.group_id, 
                this.shifts.id
            )

            document.querySelector('.page-title').innerHTML = 'Dettaglio Turno'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        toSchedule(){
            this.reactivate = true
        },
        scheduleShift(bool, item){
            this.reactivate = bool
            this.shifts = item
        },
        async schedule(){

            if(this.statusEditable){
                var scheduleData = {
                    date_start : this.shifts.date_start,
                    date_end : this.shifts.date_end,
                    group_id : this.shifts.group_id,
                    week_number : this.shifts.week_number,
                    week_status : 'pianificato',
                    year : this.shifts.year
                }

                await ScheduleService.updateSchedule(localStorage.getItem('token'), scheduleData, this.shifts.id)

                var schedulesList = JSON.parse(localStorage.getItem('schedulesList'))

                Array.from(schedulesList).map((schedule) => {
                    if(this.shifts.id == schedule.id){
                        schedule.week_status = "pianificato";
                    }
                })

                localStorage.setItem('schedulesList', JSON.stringify(schedulesList))
            }

            this.reactivate = false
            this.$router.go()
        },
        async archiveShift(){

            var scheduleData = {
                date_start : this.shifts.date_start,
                date_end : this.shifts.date_end,
                group_id : this.shifts.group_id,
                week_number : this.shifts.week_number,
                week_status : 'archiviato',
                year : this.shifts.year
            }

            await ScheduleService.updateSchedule(localStorage.getItem('token'), scheduleData, this.shifts.id)

            var schedulesList = JSON.parse(localStorage.getItem('schedulesList'))

            Array.from(schedulesList).map((schedule) => {
                if(this.shifts.id == schedule.id){
                    schedule.week_status = "archiviato"
                }
            })

            localStorage.setItem('schedulesList', JSON.stringify(schedulesList))
            
            this.archive = false
            this.$router.go()
        },
        draftShift(){
            this.drafted = true
        },
        async draft(){

            var scheduleData = {
                date_start : this.shifts.date_start,
                date_end : this.shifts.date_end,
                group_id : this.shifts.group_id,
                week_number : this.shifts.week_number,
                week_status : 'bozza',
                year : this.shifts.year
            }

            await ScheduleService.updateSchedule(localStorage.getItem('token'), scheduleData, this.shifts.id)

            var schedulesList = JSON.parse(localStorage.getItem('schedulesList'))

            Array.from(schedulesList).map((schedule) => {
                if(this.shifts.id == schedule.id){
                    schedule.week_status = "bozza"
                }
            })

            localStorage.setItem('schedulesList', JSON.stringify(schedulesList))

            this.drafted = false
            this.$router.go()
        },
        verifyConstraints(){
            this.verify = true
        },
        shopVerifyConstraints(){
            this.shopVerify = true
        },
        close(){
            this.verify = false
            this.daysOff = false
            this.daysOffOk = false
        },
        closeShops(){
            this.shopVerify = false
        },
        back(bool){
            this.edit = bool
            this.visible = bool
            this.toVisit = false
            document.querySelector('.page-title').innerHTML = 'Elenco Turni'
            document.querySelector('.back-arrow').style.visibility = 'hidden'
        },
        backToList(){
            this.edit = false
            this.visible = false
            this.updated = false
            document.querySelector('.page-title').innerHTML = 'Elenco Turni'
            document.querySelector('.back-arrow').style.visibility = 'hidden'
        },
        async editShift(id){
            // var itemId = id
            this.edit = false
            this.visible = true
            this.toVisit = false
            this.updated = true

            this.nrWeek = id
            this.created = true
            var shopsList = [];

            var createShiftList = JSON.parse(localStorage.getItem('createShiftList'))

            Array.from(createShiftList).map((id) => {
                if(!shopsList.includes(id)) shopsList.push(id)
            })

            this.scheduleList = await ShiftsObjects.updateShiftObject(
                localStorage.getItem('token'), 
                this.shifts.year,
                this.shifts.week_number, 
                this.shifts.group_id, 
                shopsList,
                this.shifts.id
            )

            var storeData = {
                "shop_id" : shopsList,
                "scheduling_id" : this.shifts.id
            }

            await StorePlanningService.createStorePlanning(localStorage.getItem('token'), storeData)
        },
        async getShiftsList(){
            this.shiftsList = JSON.parse(localStorage.getItem('schedulesList'))
        },
        openDaysOff(){
            this.daysOff = true
        },
        closeDaysOff(){
            this.daysOffError = false
        },
        async updateDaysOff(daysOff){

            const DaysOff = await DaysOffService.updateAllDaysOff(localStorage.getItem('token'), daysOff)
            
            var handler = daysOffErrorHandler(DaysOff)

            if(handler.error){
                this.daysOffError = handler.error
                this.errorMessage = handler.message
                this.daysOffDate = handler.date
                this.user_id = handler.user_id
            }else{
                this.updated = handler.updated
                this.daysOffOk = true
            }
        }
    },
    computed : {
        statusList(){
            return [
                {full_name : 'Pianificati', status : 'pianificato'},
                {full_name : 'In corso', status : 'in corso'},
                {full_name : 'Archiviati', status : 'archiviato'},
                {full_name : 'Passati', status : 'passato'},
                {full_name : 'In bozza', status : 'bozza'},
                {full_name : 'Tutti', status : 'tutti'},
            ]
        },
        padding(){
            if(window.innerWidth > 768){
                return '20px'
            }else{
                return '5px'
            }
        }
    },
    watch : {
        status(value){
            var list = JSON.parse(localStorage.getItem('schedulesList'))
            var filteredList = []
            Array.from(list).map((item) => {
                if(item.week_status == value){
                    filteredList.push(item)
                }
            })

            if(value !== 'tutti'){
                this.shiftsList = filteredList
            }else{
                this.shiftsList = list
            }
        },
        toVisit(value){
            if(value){
                this.editable = true
            }else{
                this.editable = false
            }
        },
        shift(value){

            if(value.week_status == 'in corso' || value.week_status == 'passato'){
                this.statusEditable = false
            }else{
                this.statusEditable = true
            }
        },
        async shops(value){
            this.scheduleList = await ShiftsObjects.updateShiftObject(
                localStorage.getItem('token'), 
                this.shifts.year,
                this.shifts.week_number, 
                this.shifts.group_id, 
                value,
                this.shifts.id
            )
        },
        async shifts(value){
            
            this.scheduleList = await ShiftsObjects.getShiftObject(
                localStorage.getItem('token'),
                value.year,
                value.week_number,
                value.group_id,
                value.id
            )
        }
    },
    async mounted(){

        var list = await ScheduleService.getAllSchedules(localStorage.getItem('token'))
        localStorage.setItem('schedulesList', JSON.stringify(list))
        var filteredList = []
        Array.from(list).map((item) => {
            if(item.week_status == 'pianificato' || item.week_status == 'in corso'){
                filteredList.push(item)
            }
        })

        this.shiftsList = filteredList
    }
}

</script>