<template>
    <div class="main">
        <PageTitle />
        <div class="window">
            <div class="section section--detail">
                <div class="detail-container">
                    <div class="detail-main-container">
                        <InspectorDetailForm :id="false" ref="inspectorForm" :options="options" :edit="true" :listEdit="true" :visible="false" />
                        <EditButtons toUpdate="Aggiungi ispettore" resetText="Reset campi" @validate="validate"
                            @cancel="reset()" />
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="added" title="Ispettore aggiunto" @redirect="redirect" />
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrollare i dati o riprovare più tardi" @redirect="redirect" />
    </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import InspectorDetailForm from '../../components/forms/InspectorDetailForm.vue';
import EditButtons from '../../components/tools/EditButtons.vue';
import WorkerService from '../../services/workerService';
import NoteService from '../../services/notesService';
import Modal from '../../components/tools/Modal.vue'
import dayjs from 'dayjs';

</script>

<script>

export default {
    name: 'add-inspectors',
    components: {
        InspectorDetailForm,
        EditButtons,
        PageTitle,
        Modal
    },
    data() {
        return {
            archive: null,
            erase: null,
            edit: null,
            error: false,
            options: {
                id: '',
                name: '',
                lastname: '',
                email: '',
                phone: '',
                address: '',
                city: '',
            },
            added: false
        }
    },
    methods: {
        async validate() {
            const inspectorForm = this.$refs.inspectorForm
            const isFormValid = await inspectorForm.validate()

            if (isFormValid && !inspectorForm.v$.$error) {
                this.edit = false
                this.added = true

                var workerData = {
                    "full_name": inspectorForm.name + ' ' + inspectorForm.surname,
                    "name": inspectorForm.name,
                    "surname": inspectorForm.surname,
                    "badge_number": null,
                    "email": inspectorForm.email,
                    "phone": inspectorForm.phone,
                    "address": inspectorForm.address,
                    "city": inspectorForm.city,
                    "status": 'attivo',
                    "role": 'ispettore'
                }

                const addWorker = await WorkerService.addWorker(localStorage.getItem('token'), workerData)

                if (addWorker !== false) {
                    
                    workerData.id = addWorker.id
                    const Notes = inspectorForm.message

                    Notes.map(async (note) => {

                        if (note !== '') {

                            var noteData = {
                                "message": note,
                                "datetime": dayjs().format('DD/MM/YYYY'),
                                "entity_type": 'user',
                                "user_id": addWorker.id,
                                "shop_id": null
                            }

                            await NoteService.addNote(localStorage.getItem('token'), noteData)
                        }

                    })

                    var workersList = JSON.parse(localStorage.getItem('workersList'))
                    workersList.push(workerData)
                    localStorage.setItem('workersList', JSON.stringify(workersList))

                }else{
                    this.error = true
                }

            } else {
                return
            }
        },

        reset() {
            this.$refs.workerForm.name = ''
            this.$refs.workerForm.surname = ''
            this.$refs.workerForm.email = ''
            this.$refs.workerForm.phone = ''
            this.$refs.workerForm.address = ''
            this.$refs.workerForm.city = ''
        },
        redirect() {
            this.added = false
            this.error = false
            this.$router.push('/elenco-ispettori')
        }
    }
}

</script>