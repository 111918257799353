<template>
  <div class="main">
    <PageTitle />
    <div class="window">
      <div class="section section--detail">
        <div class="detail-container">
          <div class="detail-main-container">
            <GroupDetailForm ref="groupForm" :notes="notes" :options="options" :edit="true" :listEdit="true" :visible="false"/>
            <EditButtons toUpdate="Aggiungi gruppo" resetText="Reset campi" @validate="validate" @cancel="reset()" />
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="added" title="Gruppo aggiunto" text="Gruppo aggiunto correttamente" @redirect="redirect"/>
    <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrolla i dati o riprova più tardi" @redirect="redirect" />
  </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import EditButtons from '../../components/tools/EditButtons.vue';
import GroupDetailForm from '../../components/forms/GroupDetailForm.vue'
import GroupService from '../../services/groupService';
import Modal from '../../components/tools/Modal.vue';

// import dayjs from 'dayjs';

</script>

<script>

export default {
  name: "add-sales-points",
  components: {
    EditButtons,
    GroupDetailForm,
    PageTitle,
    Modal
  },
  data() {
    return {
      archive: null,
      erase: null,
      edit: null,
      options: {
        group_name: '',
      },
      added: false
    }
  },
  methods: {
    groupArchive(bool) {
      this.archive = bool
    },
    groupEdit(bool) {
      this.edit = bool
    },
    groupDelete(bool) {
      this.erase = bool
    },
    editsCancel(bool) {
      this.edit = bool
    },
    // async addGroup(groupData){
    //   await GroupService.addGroup(localStorage.getItem('token'), groupData)
    // },
    async validate() {
      const groupForm = this.$refs.groupForm
      const isFormValid = groupForm.validate()
      if (isFormValid && !groupForm.v$.$error) {
        this.edit = false
        this.added = true

        var groupData = {
          "group_name": groupForm.group_name,
          "status": 'attivo'
        }
        
        const addGroup = await GroupService.addGroup(localStorage.getItem('token'), groupData)

        // const allGroups = await GroupService.getGroups(localStorage.getItem('token'))

        // localStorage.setItem('groupsList', JSON.stringify(allGroups))

        if(addGroup !== false){

          groupData.id = addGroup.id

          var groupsList = JSON.parse(localStorage.getItem('groupsList'))
          groupsList.push(groupData)
          localStorage.setItem('groupsList', JSON.stringify(groupsList))

        }else{
          this.error = true
        }
      } else {
        return
      }
    },
    reset(){
      this.$refs.groupForm.group_name = ''
    },
    redirect(){
      this.error = false
      this.updated = false
      this.$router.push('/elenco-gruppi')
    }
  }
}

</script>