<template>
  <div class="main">
    <PageTitle />
    <div class="window">
      <div class="section section--detail">
        <div class="detail-container">
          <div class="detail-main-container">
            <SalesPointsDetailForm ref="salesPointsForm" :notes="notes" :options="options" :edit="false" :listEdit="true" :visible="false"/>
            <EditButtons toUpdate="Aggiungi punto vendita" resetText="Reset campi" @validate="validate" @cancel="reset()" />
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="added" title="Punto vendita aggiunto" text="Punto vendita aggiunto correttamente" @redirect="redirect"/>
    <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrolla i dati o riprova più tardi" @redirect="redirect" />
  </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import EditButtons from '../../components/tools/EditButtons.vue';
import SalesPointsDetailForm from '../../components/forms/SalesPointsDetailForm.vue'
import ShopService from '../../services/shopService';
import NoteService from '../../services/notesService';
import { setShop } from '../../setters';
import Modal from '../../components/tools/Modal.vue'

import dayjs from 'dayjs';

</script>

<script>

export default {
  name: "add-sales-points",
  components: {
    EditButtons,
    SalesPointsDetailForm,
    PageTitle,
    Modal
  },
  data() {
    return {
      archive: null,
      erase: null,
      error: false,
      edit: null,
      options: {
        full_name: '',
        group: '',
        director_name: '',
        director_phone: '',
        inspector_id: '',
        address: '',
        num_monthly_hours : '',
        city: '',
      },
      notes: null,
      added: false
    }
  },
  methods: {
    salesPointsArchive(bool) {
      this.archive = bool
    },
    salesPointsEdit(bool) {
      this.edit = bool
    },
    salesPointsDelete(bool) {
      this.erase = bool
    },
    editsCancel(bool) {
      this.edit = bool
    },
    async addShop(shopData){
      await ShopService.addShop(localStorage.getItem('token'), shopData)
    },
    async validate() {
      const salesPointsForm = this.$refs.salesPointsForm
      const isFormValid = salesPointsForm.validate()
      if (isFormValid && !salesPointsForm.v$.$error) {

        this.edit = false
        this.added = true
        var shopData = setShop(salesPointsForm, 'attivo')
        const addShop = await ShopService.addShop(localStorage.getItem('token'), shopData)

        if(addShop !== false){

          shopData.id = addShop.id

          const Notes = salesPointsForm.message

          Notes.map( async (note) => {

              if(note !== ''){

                  var noteData = {
                      "message" : note,
                      "datetime" : dayjs().format('DD/MM/YYYY'),
                      "entity_type" : 'shop',
                      "user_id" : null,
                      "shop_id" : addShop.id
                  }

                await NoteService.addNote(localStorage.getItem('token'), noteData)
              }

          })

          var shopsList = JSON.parse(localStorage.getItem('shopsList'))
          shopsList.push(shopData)
          localStorage.setItem('shopsList', JSON.stringify(shopsList))

        }else{
          this.error = true
        }
      }else{
        return
      }
    },
    reset(){
      this.$refs.salesPointsForm.full_name = ''
      this.$refs.salesPointsForm.inspector = ''
      this.$refs.salesPointsForm.director = ''
      this.$refs.salesPointsForm.director_phone = ''
      this.$refs.salesPointsForm.address = ''
      this.$refs.salesPointsForm.city = ''
      this.$refs.salesPointsForm.group_id = ''
      this.$refs.salesPointsForm.num_monthly_hours = ""
    },
    redirect(){
      this.error = false
      this.updated = false
      this.$router.push('/elenco-punti-vendita')
    }
  }
}

</script>