<template>
    <div class="single-constraint" :class="situation()">
        <div class="container">
            <div class="title">
                {{ name }}
            </div>
            <div class="description">
                {{ info }} - {{ tot_hours }}/{{ numHours }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name : 'single-constraint',
    props : {
        name : {
            default : null,
            type : String
        },
        status : {
            default : null,
            type : String
        },
        num_hours : {
            default : null,
            type : String
        },
        tot_hours : {
            default : null,
            type : String
        }
    },
    methods : {
        situation(){
            if(this.status == 'ok'){
                return ' success'
            }else if(this.status == 'more'){
                return ' error'
            }else{
                return ' warning'
            }
        }
    },
    computed : {
        info(){
            if(this.status == 'ok'){
                return 'Monte ore completo'
            }else if(this.status == 'more'){
                return 'Monte ore superato'
            }else if(this.status == 'less'){
                return 'Monte ore non completo'
            }else{
                return 'Totale ore'
            }
        },
        numHours(){
            if(this.num_hours !== null){
                return this.num_hours
            }else{
                return '-';
            }
        }
    }
}

</script>