import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Export from '../views/Administration/Export.vue'
import AddInspectors from '../views/Employees/AddInspectors.vue'
import AddWorkers from '../views/Employees/AddWorkers.vue'
import InspectorsList from '../views/Employees/InspectorsList.vue'
import WorkersList from '../views/Employees/WorkersList.vue'
import SalesPointsList from '../views/SalesPoints/SalesPointsList.vue'
import AddSalesPoints from '../views/SalesPoints/AddSalesPoints.vue'
import CreateShift from '../views/Shifts/CreateShift.vue'
import ShiftsList from '../views/Shifts/ShiftsList.vue'
import Summary from '../views/Shifts/Summary.vue'
import Login from '../views/Login.vue'
import Notifications from '../views/Notifications.vue'
import GroupsList from '../views/SalesPoints/GroupsList.vue'
import AddGroups from '../views/SalesPoints/AddGroups.vue'


const routes = [
 
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/esportazione-report', 
    name: 'Esportazione Report',
    component: Export,
  },
  {
    path: '/elenco-punti-vendita',
    name: 'Elenco punti vendita',
    component: SalesPointsList,
  },
  {
    path: '/aggiungi-punto-vendita',
    name: 'Aggiungi punto vendita',
    component: AddSalesPoints,
  },
  {
    path: '/elenco-gruppi',
    name: 'Elenco gruppi',
    component: GroupsList,
  },
  {
    path: '/aggiungi-gruppo',
    name: 'Aggiungi gruppo',
    component: AddGroups,
  },
  {
    path: '/elenco-operatori',
    name: 'Elenco operatori',
    component: WorkersList,
  },
  {
    path: '/aggiungi-operatori',
    name: 'Aggiungi operatori',
    component: AddWorkers,
  },
  {
    path: '/elenco-ispettori',
    name: 'Elenco ispettori',
    component: InspectorsList,
  },
  {
    path: '/aggiungi-ispettori',
    name: 'Aggiungi ispettori',
    component: AddInspectors,
  },
  {
    path: '/elenco-turni',
    name: 'Elenco Turni',
    component: ShiftsList,
  },
  {
    path: '/crea-turno',
    name: 'Crea turno',
    component: CreateShift,
  },
  {
    path: '/riepilogo',
    name: 'Riepilogo',
    component: Summary,
  },
  {
    path : '/dettaglio-operatore',
    name : 'Dettaglio Operatore'
  },
  {
    path : '/notifiche',
    name : 'Notifiche',
    component: Notifications
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes : routes
})

export default router
