import axios from "axios";
import LocalStorage from './localStorage'
import StatusService from "./statusService";
import { endpoint } from "../dataService";

class Login{

    constructor(){

        this.url = `${endpoint()}/api/login`

    }

    async userLogin(userData){

        var success = false;

        await axios.post(this.url, userData)
        
        .then(function(response){
            LocalStorage.setToken(response.data.data.token)
            
            if(response.data.success == true){
                success = true
            }else{
                success = false
            }

        })
        
        .catch(function (error){

            console.log(error)
            StatusService.unauthorized(error.response.status)

        })

        return success
    }

}

export default new Login()