<template>
    <div class="pdf-template">
        <div v-for="(schedule, index) in object" class="schedule" :key="index">
            <div class="schedule-container">
                <div class="name">
                    {{ schedule.shop_name }}
                </div>
                <div class="headings">
                    <div class="heading">GIORNO</div>
                    <div class="heading">MATTINO</div>
                    <div class="heading">POMERIGGIO</div>
                    <div class="heading">SERA</div>
                </div>
                <div v-for="(shift, j) in schedule.shifts" :key="j" class="shifts">
                    <div class=" day"> {{ (j < 6) ? day(j + 1) : day(0) }} {{ shift.date }} </div>
                    <div class="morning"> 
                        <div>
                            {{ (shift.morning.time_start) ? shift.morning.time_start + ' ' +
                                shift.morning.time_end + ' ' + shift.morning.worker : '' }} 
                            
                            {{ dayOff(schedule.shop_id, shift.date) }}
                            
                            
                        </div>
                        <div>{{ (shift.morning.message !== null) ? shift.morning.message : '' }}</div>       
                    </div>
                    <div class="afternoon"> 
                        <div>
                            {{ (shift.afternoon.time_start) ? shift.afternoon.time_start + ' ' +
                                shift.afternoon.time_end + ' ' + shift.afternoon.worker : '' }}
                        </div>
                        <div>{{ (shift.afternoon.message !== null) ? shift.afternoon.message : '' }}</div>    
                    </div>
                    <div class="evening"> 
                        <div class="">
                            {{ (shift.evening.time_start) ? shift.evening.time_start + ' ' +
                                shift.evening.time_end + ' ' + shift.evening.worker : '' }}
                        </div>    
                        <div>{{ (shift.evening.message !== null) ? shift.evening.message : '' }}</div>
                    </div>
                </div>
            </div>
            <div class="html2pdf__page-break"></div>
        </div>
    </div>
</template>

<script>

import ShiftsObjects from '../../services/shiftsObjects';
import { isDayOff } from '../../dataService';
import dayjs from 'dayjs';

require('dayjs/locale/it')


export default {
    name: 'pdf-template',
    props: {
        object: {
            default: null,
            type: Array
        },
        daysOff: {
            default: null,
            type: Array
        }
    },
    methods: {
        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
       
        day(date){
            return dayjs().day(date).locale('it').format('dddd')
        },
        dayOff(id, date){

            date = date.split('-').reverse().join('-')

            var result = '';

            Array.from(this.daysOff).map((dayOff) => {
                if(id == dayOff.user_id && isDayOff(dayOff, date) == true){
                    console.log('dentro', 'id :', id, 'date :', date)
                    result = 'RIPOSO'
                }
            })

            return result
        }
    },
    data() {
        return {
            schedules: [],
        }
    },
    watch: {
        async object(value) {
            if (value.length !== 0) {
                var schedules = await ShiftsObjects.getShiftObjectByWorker(localStorage.getItem('token'), this.object.year, this.object.weekNumber, this.object.id)
                this.schedules = schedules
            }
        }
    },
}

</script>

<style lang="scss" scoped>
.pdf-template {
    width: 100%;

    .schedule {
        margin: 100px auto 0;
        width: 95%;

        .schedule-container{
            border: 1px solid black;
            height: 100%;
            margin: auto 0;
            padding: 20px;
            width: 100%;
            
            .name{
                text-transform: uppercase;
            }

            .headings,
            .shifts {
                border-bottom: 1px solid black;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                padding-bottom: 5px;
                padding-top: 5px;
            }
        }
    }
}
</style>