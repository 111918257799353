<template>
    <div class="section section--form-detail">
        <div class="form-container">
            <FormInput labelText="Nome Punto Vendita*" :disabled="(listEdit) ? false : true" v-model:value="full_name"
                :errors="v$.full_name.$errors" />
            <FormSelect labelText="Gruppo*" :disabled="(listEdit) ? false : true" :workerSelect="true"
                :item-list="groups" v-model:value="group_id" :errors="v$.group_id.$errors" />
            <FormInput labelText="Indirizzo punto vendita" :disabled="(listEdit) ? false : true"
                v-model:value="address" />
            <FormInput labelText="Città" :disabled="(listEdit) ? false : true" v-model:value="city" />
            <FormInput labelText="Nome Direttore" :disabled="(listEdit) ? false : true" v-model:value="director_name" />
            <FormInput type="tel" labelText="Recapito Direttore" :disabled="(listEdit) ? false : true"
                v-model:value="director_phone" :errors="v$.director_phone.$errors" />
            <FormInput type="number" labelText="Monte ore" :disabled="(listEdit) ? false : true"
                v-model:value="num_monthly_hours" />
            <FormSelect labelText="Ispettore" :disabled="(listEdit) ? false : true" :workerSelect="true"
                :item-list="inspectorList" v-model:value="inspector_id" />
        </div>
        <div class="notes-container">
            <div class="title">Note</div>
            <Button v-if="edit" class="add-notes" id="add" buttonType="brand" rounded="true" text="Aggiungi una nota"
                icon="close" status="" @click="addNoteField()" />
        </div>
        <Notes ref="notesField" v-for="(note, index) in formNotes" :id="index" :disabled="(listEdit) ? false : true"
            @deleteNote="deleteNote(index)" @saveNote="saveNote(index)" @editNote="editNote(index)"
            :icons="icons(index)" :visible="(!listEdit) ? false : true" icon="delete" :key="index"
            v-model:value="message[index]" class="note-field" />
    </div>
</template>

<script>

import FormInput from '../tools/FormInput.vue';
import FormSelect from '../tools/FormSelect.vue';
import Button from '../tools/Button.vue';
import Notes from '../Notes.vue';
import NotesService from '../../services/notesService';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import dayjs from 'dayjs';

const valid_phone = helpers.regex(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\|\s)?(\d{3})(\|\s)?(\d{3,6})$/)

export default {
    name: 'sales-points-detail-form',
    components: {
        FormInput,
        FormSelect,
        Notes,
        Button,
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            address: (this.options.address) ? this.options.address : '',
            city: (this.options.city) ? this.options.city : '',
            director_name: (this.options.director_name) ? this.options.director_name : '',
            director_phone: (this.options.director_phone) ? this.options.director_phone : '',
            full_name: (this.options.full_name) ? this.options.full_name : '',
            group_id: (this.options.group_id) ? this.options.group_id : '',
            num_monthly_hours: (this.options.num_monthly_hours) ? this.options.num_monthly_hours : '',
            groups: [],
            inspectorList: [],
            inspector_id: (this.options.inspector_id) ? this.options.inspector_id : '',
            formNotes: null,
            message: [],
        }
    },
    props: {
        edit: {
            default: false,
            type: Boolean
        },
        options: {
            full_name: {
                default: null,
                type: String
            },
            inspector: {
                default: null,
                type: String
            },
            director: {
                default: null,
                type: String
            },
            director_phone: {
                default: null,
                type: String
            },
            address: {
                default: null,
                type: String
            },
            city: {
                default: null,
                type: String
            },
            group_id: {
                default: null,
                type: String
            },
            num_monthly_hours: {
                default: null,
                type: String
            },
            notesIcons: {
                default: true,
                type: Boolean
            }
        },
        notes: {
            default: null,
            type: Array
        },
        listEdit: {
            default: null,
            type: Boolean
        },
        visible: {
            default: true,
            type: Boolean
        }
    },
    methods: {
        async validate() {
            return await this.v$.$validate()
        },
        addNoteField() {
            this.formNotes++
            this.message.push([])
        },
        async deleteNote(index) {

            await NotesService.deleteNote(localStorage.getItem('token'), this.notesList[index].id)

            // this.notesList.splice(index, 1)
            // this.message.splice(index, 1)
        },
        async saveNote(index) {

            var noteData = {
                "message": this.message[index],
                "datetime": dayjs().format('DD/MM/YYYY'),
                "entity_type": 'user',
                "user_id": null,
                "shop_id": this.options.id
            }

            var newNote = await NotesService.addNote(localStorage.getItem('token'), noteData)

            this.notesList.push(newNote)
        },
        async editNote(index) {

            var noteData = {
                "message": this.message[index],
                "datetime": dayjs().format('DD/MM/YYYY'),
                "entity_type": 'user',
                "user_id": null,
                "shop_id": this.options.id
            }

            this.notesList[index].message = this.message[index]

            await NotesService.updateNote(localStorage.getItem('token'), this.notes[index].id, noteData)

            var textarea = document.querySelectorAll('.note-field')[index]

            textarea.classList.add('blur')

            setTimeout(() => {
                textarea.classList.remove('blur')
            }, 1500)

        },
        icons(index) {
            if (this.notesIcons == true) {
                if (this.notes !== null && this.notes.length > 0) {
                    if (this.notes[index]) {
                        return 1
                    } else {
                        return 2
                    }
                }
            } else {
                return 0
            }
        },
        async getWorkers() {
            var inspectorList = JSON.parse(localStorage.getItem('workersList'))
            Array.from(inspectorList).map((worker) => {
                if (worker.role == 'ispettore' && worker.status == 'attivo') {
                    this.inspectorList.push(worker)
                }
            })
        },

        async getGroups() {
            var groups = JSON.parse(localStorage.getItem('groupsList'))
            var activeGroups = []
            Array.from(groups).map((group) => {
                if (group.status == 'attivo') {
                    activeGroups.push(group)
                }
            })

            this.groups = activeGroups
        }
    },
    validations() {
        return {
            full_name: { required: helpers.withMessage('Il nome è obbligatorio', required) },
            director_phone: {
                validPhone: helpers.withMessage('Il numero inserito non è valido', valid_phone)
            },
            group_id: { required: helpers.withMessage('Il gruppo è obbligatorio', required) },
        }
    },
    watch: {
        notes(value) {
            if (value !== null && value.length > 0) {
                
                this.message = []

                value.map((note) => {
                    this.message.push(note.message)
                })

                this.formNotes = value.length
            } else {
                this.message = []
                this.formNotes = 1
            }

            this.notesList = value
        }
    },
    computed: {
        salesPointsList() {
            return JSON.parse(localStorage.getItem('shopsList'))
        },
        notesList() {
            return this.notes
        }
    },
    async mounted() {
        await this.getWorkers()
        await this.getGroups()
        if (this.notesList !== null && this.notesList.length > 0) {
            this.notesList.map((note) => {
                this.message.push(note.message)
            })
            this.formNotes = this.notesList.length
        } else {
            this.message = []
            this.formNotes = 1
        }
    }
}

</script>