<template>
    <div class="radio-button">
        <div class="button-container">
            <div class="select-area" :class="[(value) ? 'selected' : '', (error == true) ?  'error' : '']">
                <svg class="icon icon-check">
                    <use xlink:href="#check"></use>
                </svg>
            </div>
        </div>
        <input type="checkbox" name="day-off" :disabled="(disabled == true) ? true : false" id="" @change="$emit('update:value', changeValue($event.target.checked), index)">
    </div>
</template>

<script>

//import dayjs from 'dayjs';

export default {
    name: 'radio-button',
    props : {
        index: {
            default: 0,
            type: Number
        },
        value: {
            default: 0,
            type: Number
        },
        error : {
            default: false,
            type: Boolean
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    data(){
        return{
            checked: 0,
        }
    },
    methods : {
        dayOff(value){
            this.checked = (value == false) ? 0 : 1;
        },
        changeValue(value){
            if(value == true){
                return 1
            }else{
                return 0
            }
        }
    },
    watch: {
        value(val){
            if(val){
                this.checked = true
            }else{
                this.checked = false
            }
        }
    },
    mounted(){
        if(this.value == 1){
            this.checked = true
        }else{
            this.checked = false
        }
    }
}

</script>