<template>
    <div class="modal-wrapper">
        <div class="edit-shifts-container">
            <div class="heading">
                <div class="title">
                    Vuoi eliminare {{ toDelete }}?
                </div>
                <div class="close">
                    <div class="icon-container" @click="$emit('cancel', false)">
                        <svg class="icon icon-close">
                            <use xlink:href="#close"></use>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </div>
            <div class="edit-buttons-container">
                <div class="cancel" @click="$emit('cancel', false)">Annulla</div>
                <Button :text="text" buttonType="errorFull" icon="delete" rounded="true" @click="$emit('deleteProfile', id), $emit('cancel', false)"/>
            </div>
        </div>
    </div>
</template>

<script setup>

import Button from './Button.vue';

</script>

<script>

export default {
    name : 'profile-remover',
    props : {
        toDelete : {
            default : null,
            type : String
        },
        text : {
            default : null,
            type : String
        },
        profile : {
            default : null,
            type : Object
        },
        id : {
            default : null,
            type : String
        }
    },
    emits : ['cancel', 'deleteProfile'],
    methods : {
        
    }

}

</script>