import axios from "axios";
// import dayjs from "dayjs";
import StatusService from "./statusService";
import { endpoint } from "../dataService";

class AvailableUser {

    constructor(){
        this.url = `${endpoint()}/api/v1/usersoperatorwithoutshifts`
    }

    async getAvailableUser(token, date, period){

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + '?date=' + date + '&period=' + period, config)

        .then(function (response) {

            console.log('AVAILABLE USERS: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log('GET AVAILABLE USERS ERROR: ', error)
            StatusService.unauthorized(error.response.status)
            return false

        })
    }
}

export default new AvailableUser()