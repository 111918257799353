<template>
    <div class="modal-wrapper daysoff">
        <div class="edit-shifts-container">
            <div class="heading">
                <div class="title">
                    Verifica riposi
                </div>
                <svg class="icon icon--close" @click="$emit('close')">
                    <use xlink:href="#close"></use>
                </svg>
            </div>
            <div class="days-off-container">
                <div class="days-heading">
                    <div class="days">
                        <div v-for="day in 7" :key="day" class="day">
                            <p>{{ weekDay(day) }}</p>
                            <p>{{ weekDate(day) }}</p>
                        </div>
                    </div>
                </div>
                <div class="worker-days-off" v-for="(daysOff, index) in daysOffList" :key="index">
                    <div class="name">
                        {{ workerName(daysOff.user_id) }}
                    </div>
                    <div class="radio-container">
                        <div v-for="i in 7" :key="i" class="radio-day-off">
                            <FormRadio :index="i" v-model:value="daysOff[dayValue(i)]"
                                :disabled="(dashboard == true) ? true : false"
                                :error="errorHandler(daysOff.user_id, dayValue(i))" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="buttons" class="edit-buttons-container">
                <Button buttonType="" text="Annulla" @click="$emit('close')" />
                <Button buttonType="successFull" text="Conferma" icon="check" :rounded="true"
                    @click="$emit('saveDaysOff', daysOffList)" />
            </div>
        </div>
    </div>
</template>

<script>

import DaysOffService from '../../services/daysOffService';
import FormRadio from '../tools/FormRadio.vue';
import Button from '../tools/Button.vue';
import { setEmptyDaysOff } from '../../setters'
import { getWorker } from '../../getters';
import { alphabetic } from '../../dataService';
import dayjs from 'dayjs';

export default {
    name: 'daysoff-modal',
    components: {
        FormRadio,
        Button
    },
    props: {
        update: {
            default: false,
            type: Boolean
        },
        week: {
            default: dayjs().week(),
            type: Number
        },
        year: {
            default: dayjs().day(6).format('YYYY'),
            type: Number
        },
        buttons: {
            default: true,
            type: Boolean
        },
        daysOffDate: {
            default: '',
            type: String
        },
        user_id: {
            default: null,
            type: Number
        },
        dashboard: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            daysOffList: [],

        }
    },
    methods: {
        dayValue(number) {
            
            var day = dayjs().day(number).format('dddd')
            day = day.toLowerCase()
            return day
        },
        workerName(id) {

            var workers = JSON.parse(localStorage.getItem('workersList'))
            var name

            Array.from(workers).map((worker) => {

                if (worker.id == id) {
                    name = worker.full_name
                }
            })

            return name
        },
        weekDay(day) {
            if (window.innerWidth > 768) {

                return dayjs().year(this.year).week(this.week).day(day).locale('it').format('dddd')
            } else {

                return dayjs().year(this.year).week(this.week).day(day).locale('it').format('dd')
            }
        },
        weekDate(day) {

            if (window.innerWidth > 768) {

                return dayjs().year(this.year).week(this.week).day(day).format('DD/MM/YYYY')
            } else {

                return dayjs().year(this.year).week(this.week).day(day).format('DD/MM')
            }
        },
        errorHandler(id, day) {

            if (id == this.user_id && day == this.daysOffDate) {
                return true
            } else {
                return false
            }
        }
    },
    computed: {

    },
    async mounted() {

        var workersList = JSON.parse(localStorage.getItem('workersList'))
        this.daysOffList = await DaysOffService.getWeekDaysOff(localStorage.getItem('token'), this.year, this.week)
        var ids = []
        var itemsList = []

        if (this.daysOffList !== false) {

            Array.from(this.daysOffList).map((dayOff) => {

                var worker = getWorker(dayOff.user_id);
                dayOff.full_name = worker.full_name
                ids.push(dayOff.user_id)
                itemsList.push(dayOff)
            })
        } else {

            this.daysOffList = []
        }
        // console.log(workersList)

        Array.from(workersList).map((worker) => {

            if (!ids.includes(worker.id) && worker.status == 'attivo' && worker.role == 'operatore') {

                var list = setEmptyDaysOff()
                list.user_id = worker.id
                list.week_number = this.week
                list.year = this.year
                list.full_name = worker.full_name
                itemsList.push(list)
                // console.log(list)
            }
        })

        this.daysOffList = itemsList.sort(alphabetic)
        // console.log(itemsList)
    }
}

</script>