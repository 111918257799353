<template>
    <div class="main">
        <PageTitle @back="back" />
        <div class="window">
            <FormSelect v-if="!visible && !edit" class="status-select" labelText="Filtra" :item-list="statusList" v-model:value="status" />
            <WorkerDetail v-if="visible || edit" :options="worker" :days-off="daysOff" :notes="notes" :listEdit="edit" @daysOff="workerDaysOff"/>
            <List v-if="!visible && !edit" :item-list="workersList" @edit="workerEdit"
                @archive="workerArchive" @visible="workerVisible" @active="active" />
            <ProfileRemover v-if="erase" :profile="worker" :id="id" text="Elimina operatore" toDelete="l'operatore"
                @cancel="cancel" @deleteProfile="deleteProfile" />
        </div>
        <Modal v-if="archive" title="Operatore archiviato" text="Operatore archiviato con successo" @redirect="redirect"/>
        <Modal v-if="reactivate" title="Operatore riattivato" text="Operatore riattivato con successo" @redirect="redirect"/>
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrollare i dati o riprovare più tardi" @redirect="redirect" />
    </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import WorkerDetail from '../../components/WorkerDetail.vue';
import List from '../../components/employees/workers/List.vue';
import ProfileRemover from '../../components/tools/ProfileRemover.vue';
import WorkerService from '../../services/workerService';
import ShopService from '../../services/workerService';
import FormSelect from '../../components/tools/FormSelect.vue';
import Modal from '../../components/tools/Modal.vue';
import { setWorker } from '../../setters';

</script>

<script>

const alphabetic = (a, b) => {
    if(a.surname < b.surname){
        return -1
    }else if(a.surname > b.surname){
        return 1
    }
    return 0
}

export default {
    name: 'workers-list',
    components: {
        PageTitle,
        WorkerDetail,
        List,
        ProfileRemover,
        FormSelect,
        Modal
    },
    data() {
        return {
            archive: false,
            daysOff: null,
            delete: false,
            edit: false,
            erase: false,
            error: false,
            id: null,
            notes: [],
            reactivate: null,
            status: 'attivo',
            visible: false,
            worker: null,
            workersList: [],
        }
    },
    methods: {
        async workerVisible(bool, item, notes, daysOff) {
            this.visible = bool
            this.worker = item
            this.notes = await notes
            this.daysOff = await daysOff


            document.querySelector('.page-title').innerHTML = 'Dettaglio operatore'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        async workerEdit(bool, item, notes, daysOff) {
            this.edit = bool
            this.worker = item
            this.notes = await notes
            this.daysOff = await daysOff

            document.querySelector('.page-title').innerHTML = 'Modifica operatore'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        async workerArchive(bool, item) {

            this.worker = item

            var workerData = setWorker(this.worker, 'archiviato', 'indeterminato', 'operatore')

            console.log('worker data: ', workerData)

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.worker.id)

            console.log(Worker)

            if(Worker !== false){

                this.archive = bool

                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {

                    if(this.worker.id == worker.id){

                        worker.status = 'archiviato'
                    }
                })

                localStorage.setItem('workersList', JSON.stringify(workersList))
                
            }else{
                this.error = true
            }

        },
        async active(bool, item) {

            this.worker = item

            var workerData = setWorker(this.worker, 'attivo', 'indeterminato', 'operatore')

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.worker.id)

            if(Worker !== false){
                
                this.reactivate = bool

                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {

                    if(this.worker.id == worker.id){

                        worker.status = 'attivo'
                    }
                })

                localStorage.setItem('workersList', JSON.stringify(workersList))
            }else{
                this.error = true
            }
        },
        cancel(bool) {

            this.erase = bool
        },
        back(bool) {

            this.edit = bool
            this.visible = bool
            document.querySelector('.page-title').innerHTML = 'Elenco operatori'
            document.querySelector('.back-arrow').style.visibility = 'hidden'

        },
        async getWorkers() {

            var workersList = await WorkerService.getWorkers(localStorage.getItem('token'))

            Array.from(workersList).map((worker) => {

                if(worker.role == 'operatore'){

                    this.workersList.push(worker)
                }
            })

            return this.workersList
        },
        async deleteProfile(id){
            await ShopService.deleteShop(localStorage.getItem('token'), id)
        },
        workerDaysOff(array){
            this.daysOff = array
        },
        redirect(){
            this.archive = false
            this.reactivate = false
            this.error = false
            this.$router.go()
        }
    },
    watch : {
        status(value){
            var list = JSON.parse(localStorage.getItem('workersList'))
            var filteredList = []

            Array.from(list).map((item) => {

                if(item.status == value && item.role == 'operatore'){

                    filteredList.push(item)
                }
            })

            if(value !== 'tutti'){

                this.workersList = filteredList.sort(alphabetic)
            }else{

                var workersList = JSON.parse(localStorage.getItem('workersList'))
                var actualList = []

                Array.from(workersList).map((worker) => {

                    if(worker.role == 'operatore'){

                        actualList.push(worker)
                    }
                })

                this.workersList = actualList.sort(alphabetic)
            }
        }
    },
    computed : {
        statusList(){
            return [
                {full_name : 'Attivi', status : 'attivo'},
                {full_name : 'Archiviati', status : 'archiviato'},
                {full_name : 'Tutti', status : 'tutti'},
            ]
        }
    },
    async mounted() {

            

            var list = await this.getWorkers()
            list = list.sort(alphabetic)
            var filteredList = []

            Array.from(list).map((item) => {

                if(item.status == this.status && item.role == 'operatore'){

                    filteredList.push(item)
                }
            })

            if(this.status !== 'tutti'){
                this.workersList = filteredList.sort(alphabetic)

            }else{

                this.workersList = list
            }
    }
}

</script>