<template>
    <div class="main">
        <PageTitle @back="back" />
        <div class="window">
            <Scheduler v-if="created" :init="scheduleList" :year="year" :schedule-id="scheduleId" :scheduleDate="startDate"
                :weekNumber="nrWeek" :group_id="groupId" @back="back" @schedule="schedule" @archive="draft" :home="false"
                @shopVerify="shopVerifyConstraints" @verify="verifyConstraints" @daysOff="openDaysOff" />
            <CreateShiftsForm v-if="!created" @createShift="createShift" />
            <DaysOffModal v-if="daysOff" :week="nrWeek" :year="year" @saveDaysOff="updateDaysOff" @close="close" 
                :days-off-date="daysOffDate" :user_id="user_id" />

            <Modal v-if="daysOffError" title="Riposi" :text="errorMessage" @redirect="closeDaysOff"/>
            <Modal v-if="daysOffOk" title="Riposi aggiornati" text="Riposi aggiornati correttamente" @redirect="close"/>
            <Modal v-if="drafted" title="Turno salvato in bozza" text="Turno salvato in bozza con successo"
                @redirect="draftShift" />
            <Modal v-if="scheduled" title="Turno pianificato" text="Il turno è stato pianificato con successo"
                @redirect="scheduleRedirect" />
            <Modal v-if="updateError" title="Si è verficato un errore, ricarica la pagina e riprova" :error="true" 
                @redirect="close"/>
            <Modal v-if="archived" title="Turno archiviato" text="Il turno è stato archiviato con successo"
                @redirect="archiveRedirect" />
            <Constraints v-if="verify" :year="startDate.substring(0, 4)" :week_number="nrWeek" :group_id="groupId"
                @close="close" />
            <ShopConstraints v-if="shopVerify" :year="startDate.substring(0, 4)" :week_number="nrWeek" :group_id="groupId"
                :scheduleId="scheduleId" @close="closeShops" />
        </div>
    </div>
</template>

<script setup>

import Scheduler from '../../components/Scheduler.vue';
import PageTitle from '../../components/PageTitle.vue';
import CreateShiftsForm from '../../components/forms/CreateShiftsForm.vue';
import ShiftsObjects from '../../services/shiftsObjects';
import ScheduleService from '../../services/scheduleService';
import StorePlanningService from '../../services/storePlanningService';
import Modal from '../../components/tools/Modal.vue';
import Constraints from '../../components/Constraints.vue';
import ShopConstraints from '../../components/ShopConstraints.vue';
import DaysOffModal from '../../components/days-off/DaysOffModal.vue';
import DaysOffService from '../../services/daysOffService';
import { daysOffErrorHandler } from '../../dataService';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayOfYear)
require('dayjs/locale/it')
const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

</script>

<script>

export default {
    name: 'create-shift',
    components: {
        Constraints,
        CreateShiftsForm,
        DaysOffModal,
        Modal,
        PageTitle,
        Scheduler,
        ShopConstraints
    },
    data() {
        return {
            archived: false,
            created: false,
            daysOff: false,
            daysOffDate: '',
            daysOffError: false,
            daysOffOk: false,
            drafted: false,
            groupId: null,
            nrWeek: null,
            scheduleId: null,
            scheduleList: [],
            scheduled: false,
            shopIds: null,
            shopVerify: false,
            startDate: null,
            updateError: false,
            user_id: null,
            verify: false,
            year: null
        }
    },
    methods: {
        async createShift(weekNr, year, groupId) {

            this.year = year
            this.startDate = dayjs().year(year).week(weekNr).day(1).format('YYYY-MM-DD')

            console.log(this.startDate)

            var scheduleData = {
                date_start: dayjs().year(year).week(weekNr).day(1).format('YYYY-MM-DD'),
                date_end: dayjs().year(year).week(weekNr).day(1).add(6, 'day').format('YYYY-MM-DD'),
                week_number: weekNr,
                group_id: groupId,
                week_status: 'bozza',
                year: year
            }

            var createShiftList = JSON.parse(localStorage.getItem('createShiftList'))

            const newSchedule = await ScheduleService.createSchedule(localStorage.getItem('token'), scheduleData)

            if (newSchedule !== false) {

                scheduleData.id = newSchedule.id

                this.groupId = groupId
                this.nrWeek = weekNr
                this.created = true
                this.scheduleId = newSchedule.id

                this.scheduleList = ShiftsObjects.createShiftObject(createShiftList, year, weekNr, newSchedule.id)

                var shopsList = [];

                Array.from(createShiftList).map((id) => {
                    if (!shopsList.includes(id)) shopsList.push(id)
                })

                var storeData = {
                    "shop_id": shopsList,
                    "scheduling_id": newSchedule.id
                }

                await StorePlanningService.createStorePlanning(localStorage.getItem('token'), storeData)

                var schedulesList = JSON.parse(localStorage.getItem('schedulesList'))
                schedulesList.push(scheduleData)
                localStorage.setItem('schedulesList', JSON.stringify(schedulesList))

            }
        },
        back() {
            this.created = false
            this.$router.go()
        },
        async schedule() {

            var scheduleData = {
                date_start: dayjs().year(this.year).week(this.nrWeek).day(1).format('YYYY-MM-DD'),
                date_end: dayjs().year(this.year).week(this.nrWeek).day(1).add(6, 'day').format('YYYY-MM-DD'),
                week_number: this.nrWeek,
                group_id: this.groupId,
                week_status: 'pianificato',
                year: this.year
            }

            const Update = await ScheduleService.updateSchedule(localStorage.getItem('token'), scheduleData, this.scheduleId)

            var schedulesList = JSON.parse(localStorage.getItem('schedulesList'))

            if(Update !== false){

                Array.from(schedulesList).map((schedule) => {
                    if (this.scheduleId == schedule.id) {
                        schedule.week_status = "pianificato"
                    }
                })

                localStorage.setItem('schedulesList', JSON.stringify(schedulesList))

                this.scheduled = true
            }else{
                this.updateError = true
            }
        },
        async archive() {

            var scheduleData = {
                date_start: dayjs().year(this.year).week(this.nrWeek).day(1).format('YYYY-MM-DD'),
                date_end: dayjs().year(this.year).week(this.nrWeek).day(1).add(6, 'day').format('YYYY-MM-DD'),
                week_number: this.nrWeek,
                group_id: this.groupId,
                week_status: 'archiviato',
                year: this.year
            }

            const Update = await ScheduleService.updateSchedule(localStorage.getItem('token'), scheduleData, this.scheduleId)

            var schedulesList = JSON.parse(localStorage.getItem('schedulesList'))

            if(Update !== false){

                Array.from(schedulesList).map((schedule) => {
                    if (this.scheduleId == schedule.id) {
                        schedule.week_status = "archiviato"
                    }
                })

                localStorage.setItem('schedulesList', JSON.stringify(schedulesList))

                this.archived = true
            }else{
                this.updateError
            }
        },
        draftShift() {
            this.drafted = false
            this.$router.push('/elenco-turni')
        },
        async draft() {

            var scheduleData = {
                date_start: dayjs().year(this.year).week(this.nrWeek).day(1).format('YYYY-MM-DD'),
                date_end: dayjs().year(this.year).week(this.nrWeek).day(1).add(6, 'day').format('YYYY-MM-DD'),
                week_number: this.nrWeek,
                group_id: this.groupId,
                week_status: 'bozza',
                year: this.startDate.substring(0, 4)
            }

            const Update = await ScheduleService.updateSchedule(localStorage.getItem('token'), scheduleData, this.scheduleId)

            if(Update !== false){

                var schedulesList = JSON.parse(localStorage.getItem('schedulesList'))

                Array.from(schedulesList).map((schedule) => {
                    if (this.scheduleId == schedule.id) {
                        schedule.week_status = "bozza"
                    }
                })

                localStorage.setItem('schedulesList', JSON.stringify(schedulesList))

                this.drafted = true
            }else{
                this.updateError = true
            }
        },
        scheduleRedirect() {
            this.scheduled = false
            this.$router.push('/elenco-turni')
        },
        archiveRedirect() {
            this.archived = false
            this.$router.push('/elenco-turni')
        },
        verifyConstraints() {
            this.verify = true
        },
        shopVerifyConstraints() {
            this.shopVerify = true
        },
        close() {
            this.verify = false
            this.daysOff = false
            this.daysOffOk = false
            this.updateError = false
        },
        closeShops() {
            this.shopVerify = false
        },
        openDaysOff() {
            this.daysOff = true
        },
        closeDaysOff(){
            this.daysOffError = false
        },

        async updateDaysOff(daysOff) {

            const DaysOff = await DaysOffService.updateAllDaysOff(localStorage.getItem('token'), daysOff)

            var handler = daysOffErrorHandler(DaysOff)

            if (handler.error) {
                this.daysOffError = handler.error
                this.errorMessage = handler.message
                this.daysOffDate = handler.date
                this.user_id = handler.user_id
            } else {
                this.updated = handler.updated
                this.daysOffOk = true
            }
        }
    }
}

</script>