<template>
    <div class="modal-wrapper confirm-modal" :class="noButton">
        <div class="edit-shifts-container">
            <div class="heading">
                <div class="title" :class="(error) ? 'error' : ''">
                    {{ title }}
                </div>
            </div>
            <div class="description">
                {{ text }}
            </div>
            <div class="edit-buttons-container">
                <Button text="Ok" :buttonType="(error) ? 'errorFull' : 'successFull'" icon="check" rounded="true" @click="$emit('redirect')"/>
                <Button v-if="cancel" text="Annulla" buttonType="errorFull" icon="close" rounded="true" @click="$emit('cancel')"/>
            </div>
        </div>
    </div>
</template>

<script setup>

import Button from './Button.vue';

</script>

<script>

export default {
    name : 'profile-remover',
    props : {
        text : {
            default : null,
            type : String
        },
        toRedirect : {
            default : null,
            type : String
        },
        title : {
            default: null,
            type : String
        },
        cancel : {
            default : false,
            type : Boolean
        },
        noButton : {
            default: '',
            type: String
        }
    },
    methods : {
        redirect(){
            this.$router.push(this.toRedirect)
        }
    }

}

</script>