<template>
    <div class="menu">
        <div class="close-bar">
            <div ref="close" class="icon-container close-menu">
                <svg :class="iconClass">
                    <use xlink:href="#close"></use>
                </svg>
            </div>
        </div>
        <div class="dashboard-link">
            <img class="house-icon" src="../../public/assets/images/svg/house.svg" alt="" />
            <router-link to="/">Dashboard</router-link>
        </div>
        <Accordeon title="Amministrazione" :children="administration" icon="euro" />
        <Accordeon title="Punti vendita" :children="salePoints" icon="shopping-cart" />
        <Accordeon title="Personale" :children="employees" icon="account-circle" />
        <Accordeon title="Turni e richieste" :children="shifts" icon="clock" />
    </div>
</template>

<script setup>

import Accordeon from './tools/Accordeon.vue';

</script>

<script>

export default {
    name: 'app-menu',
    components: {
        Accordeon
    },
    data() {
        return {
            administration: [
                ['Esportazione Report', 'esportazione-report']
            ],
            salePoints: [
                ['Elenco gruppi', 'elenco-gruppi'],
                ['Aggiungere gruppo', 'aggiungi-gruppo'],
                ['Elenco punti vendita', 'elenco-punti-vendita'],
                ['Aggiungere punto vendita', 'aggiungi-punto-vendita'],
            ],
            employees: [
                ['Elenco operatori', 'elenco-operatori'],
                ['Aggiungere operatore', 'aggiungi-operatori'],
                ['Elenco ispettori', 'elenco-ispettori'],
                ['Aggiungere ispettore', 'aggiungi-ispettori']
            ],
            shifts: [
                ['Elenco turni', 'elenco-turni'],
                ['Crea turno', 'crea-turno'],
                ['Riepilogo', 'riepilogo'],
            ],
        }
    },
}

</script>