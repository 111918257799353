<template>
    <div class="main">
        <PageTitle />
        <div class="window">
            <ExportForm />
        </div>
    </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import ExportForm from '../../components/forms/ExportForm.vue';

</script>

<script>

export default {

    name : 'export-informitalia',
    components : {
    ExportForm,
    PageTitle
}
}

</script>