<template>
    <div class="main">
        <PageTitle />
        <div class="window">
            <div class="notifications">
                <div class="constraints-container">
                    <SingleNotification v-for="(constraint, index) in constraintsList" :key="index" :name="constraint.name"
                        :status="constraint.status" :num_hours="constraint.num_monthly_hours"
                        :tot_hours="constraint.sum_number_hours" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs';
import PageTitle from '../components/PageTitle.vue';
import SingleNotification from '../components/notifications/SingleNotification.vue';
import ConstraintsService from '../services/constraintsService';

const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export default {
    name: 'verify-constraints',
    components: {
        PageTitle,
        SingleNotification
    },
    props: {
        week_number: {
            default: null,
            type: String
        },
        year: {
            default: null,
            type: String
        },
    },
    data() {
        return {
            constraintsList: []
        }
    },
    methods: {
        
    },
    async mounted() {

        var shops = []
        var constraints = await ConstraintsService
            .getShopsHours(localStorage.getItem('token'),
                dayjs().startOf('month').format('YY-MM-DD'),
                dayjs().endOf('month').format('YY-MM-DD'),
                'shop', 0)
        var shopsList = JSON.parse(localStorage.getItem('shopsList'))

        Array.from(constraints).map((constraint) => {
            Array.from(shopsList).map((shop) => {
                if (constraint.shop_id == shop.id) {
                    if ((shop.num_monthly_hours !== null && constraint.sum_number_hours > shop.num_monthly_hours)) {
                        shops.push({
                            "name": shop.full_name,
                            "sum_number_hours": constraint.sum_number_hours,
                            "num_monthly_hours": shop.num_monthly_hours,
                            "status": 'more'
                        })
                    }
                }
            })
        })

        this.constraintsList = shops
    }
}

</script>