import dayjs from "dayjs";
import ShiftsService from "./shiftsService";
import StorePlanningService from "./storePlanningService";
import { getWorker } from '../getters';

const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class ShiftsObjects {

    /**
     * 
     * @param {String} token 
     * @param {Number} year 
     * @param {Number} weekNr 
     * @param {Number} scheduleId
     * @returns Object of a scheduled week
     */

    async getShiftObject(token, year, weekNr, groupId, scheduleId) {
        const allShifts = await ShiftsService.getShifts(token, year, weekNr, groupId)
        var arrayIds = [];
        var shopIds = await StorePlanningService.getShopsIdArray(token, year, weekNr, groupId)
        var filteredArray = [];

        var scheduling_id = scheduleId

        Array.from(allShifts).map((shift) => {
            if (!arrayIds.includes(shift.shop_id)) {
                    arrayIds.push(shift.shop_id)
                }
        })

        if (shopIds.length > 0) {
            shopIds.map(() => {
                filteredArray.push([])
            })

            Array.from(shopIds).map((shift, index) => {

                var shifts = [];

                var shopsList = JSON.parse(localStorage.getItem('shopsList'))

                var shopName

                Array.from(shopsList).map((item) => {
                    if (item.id == shift) shopName = item.full_name
                })

                for (var x = 0; x < 7; x++) {
                    var date = dayjs().year(year).week(weekNr).day(1).add(x, 'day').format('YYYY-MM-DD');
                    shifts.push({
                        date: date,
                        morning: {
                            time_start: null,
                            time_end: null,
                            worker: null,
                            id: null,
                            shift_id: null,
                            message: null
                        },
                        afternoon: {
                            time_start: null,
                            time_end: null,
                            worker: null,
                            id: null,
                            shift_id: null,
                            message: null
                        },
                        evening: {
                            time_start: null,
                            time_end: null,
                            worker: null,
                            id: null,
                            shift_id: null,
                            message: null
                        }
                    })

                    filteredArray[index].group_id = groupId
                    filteredArray[index].shop_name = shopName
                    filteredArray[index].shop_id = shift
                    filteredArray[index].scheduling_id = scheduling_id
                    filteredArray[index].shifts = shifts

                }
            })

            Array.from(allShifts).map((shift) => {
                for (var j = 0; j < filteredArray.length; j++) {

                    if (shift.shop_id == filteredArray[j].shop_id) {

                        Array.from(filteredArray[j].shifts).map((currentShift) => {

                            if (currentShift.date == dayjs(shift.date).format('YYYY-MM-DD') && shift.period == 0) {

                                currentShift.morning.shift_id = shift.id
                                currentShift.morning.message = shift.message
                                currentShift.morning.time_start = shift.time_start.substring(0, 5)
                                currentShift.morning.time_end = shift.time_end.substring(0, 5)
                                currentShift.morning.worker = shift.user_name
                                currentShift.morning.id = shift.user_id
                            }
                            if (currentShift.date == dayjs(shift.date).format('YYYY-MM-DD') && shift.period == 1) {

                                currentShift.afternoon.shift_id = shift.id
                                currentShift.afternoon.message = shift.message
                                currentShift.afternoon.time_start = shift.time_start.substring(0, 5)
                                currentShift.afternoon.time_end = shift.time_end.substring(0, 5)
                                currentShift.afternoon.worker = shift.user_name
                                currentShift.afternoon.id = shift.user_id
                            }
                            if (currentShift.date == dayjs(shift.date).format('YYYY-MM-DD') && shift.period == 2) {

                                currentShift.evening.shift_id = shift.id
                                currentShift.evening.message = shift.message
                                currentShift.evening.time_start = shift.time_start.substring(0, 5)
                                currentShift.evening.time_end = shift.time_end.substring(0, 5)
                                currentShift.evening.worker = shift.user_name
                                currentShift.evening.id = shift.user_id
                            }
                        })
                    }
                }
            })

            localStorage.setItem('shopsShiftObject', JSON.stringify(filteredArray))
        }

        console.log('GET SHIFT OBJECT: ', filteredArray)

        return filteredArray;
    }

    /**
     * 
     * @param {Array} shops Shops ids Array 
     * @param {Date} weekDate Date Object of the scheduling week
     * @param {Number} scheduling_id
     * @returns New shifts Object
     */

    createShiftObject(shops, year, weekNr, scheduling_id) {
        var arrayIds = [];
        var filteredArray = [];

        shops.map((id) => {
            if (!arrayIds.includes(id)) {
                arrayIds.push(id)
            }
        })

        arrayIds.map(() => {
            filteredArray.push([])
        })

        for (var i = 0; i < arrayIds.length; i++) {

            var shopsList = JSON.parse(localStorage.getItem('shopsList'))

            var shopName
            var groupId

            Array.from(shopsList).map((shop) => {
                if (shop.id == arrayIds[i]) {
                    shopName = shop.full_name
                    groupId = shop.group_id
                }
            })

            var shifts = [];

            for (var x = 0; x < 7; x++) {

                var date = dayjs().year(year).week(weekNr).day(1).add(x, 'day').format('YYYY-MM-DD');

                shifts.push({
                    date: date,
                    morning: {
                        time_start: null,
                        time_end: null,
                        worker: null,
                        id: null,
                        shift_id: null,
                        message: null
                    },
                    afternoon: {
                        time_start: null,
                        time_end: null,
                        worker: null,
                        id: null,
                        shift_id: null,
                        message: null
                    },
                    evening: {
                        time_start: null,
                        time_end: null,
                        worker: null,
                        id: null,
                        shift_id: null,
                        message: null
                    }
                })

                filteredArray[i] = {
                    shop_id: arrayIds[i],
                    group_id: groupId,
                    shop_name: shopName,
                    scheduling_id: scheduling_id,
                    shifts: shifts
                }

            }
        }

        const comparator = (a, b) => {
            return parseInt(a.group_id) - parseInt(b.group_id);
        }

        filteredArray = filteredArray.sort(comparator)

        console.log('CREATED SHIFT OBJECT: ', filteredArray)

        return filteredArray;
    }

    /**
     * 
     * @param {String} token 
     * @param {Number} year 
     * @param {Number} weekNr 
     * @param {Array} shops Shops ids Array
     * @param {Date} weekDate Date Object of the scheduling week
     * @param {Number} scheduling_id 
     * @returns New updated shifts object
     */

    async updateShiftObject(token, year, weekNr, groupId, shops, scheduling_id) {
        var arrayIds = []
        var existentSchedule = await this.getShiftObject(token, year, weekNr, groupId, scheduling_id)

        Array.from(existentSchedule).map((shift) => {
            arrayIds.push(shift.shop_id)
        })

        shops.map((shop) => {

            if (!arrayIds.includes(shop)) {

                var shifts = [];

                var shopsList = JSON.parse(localStorage.getItem('shopsList'))

                var shopName
                var groupId

                Array.from(shopsList).map((item) => {
                    if (item.id == shop) {
                        shopName = item.full_name
                        groupId = item.group_id
                    }
                })

                for (var x = 0; x < 7; x++) {

                    var date = dayjs().year(year).week(weekNr).day(1).add(x, 'day').format('YYYY-MM-DD');

                    shifts.push({
                        date: date,
                        morning: {
                            shift_id: null,
                            time_start: null,
                            time_end: null,
                            worker: null,
                            id: null,
                            message: null
                        },
                        afternoon: {
                            shift_id: null,
                            time_start: null,
                            time_end: null,
                            worker: null,
                            id: null,
                            message: null
                        },
                        evening: {
                            shift_id: null,
                            time_start: null,
                            time_end: null,
                            worker: null,
                            id: null,
                            message: null
                        }
                    })

                    var newShift = {
                        shop_id: shop,
                        group_id: groupId,
                        shop_name: shopName,
                        scheduling_id: scheduling_id,
                        shifts: shifts
                    }

                }

                existentSchedule.push(newShift)
            }
        })

        const comparator = (a, b) => {
            if(a.shop_id < b.shop_id){
                return -1
            }else if(a.shop_id > b.shop_id){
                return 1
            }
            return 0
        }

        existentSchedule = existentSchedule.sort(comparator)

        console.log('UPDATED SHIFT OBJECT: ', existentSchedule)

        return existentSchedule.sort(comparator)

    }

    /**
     * 
     * @param {String} token 
     * @param {Number} year 
     * @param {Number} weekNr 
     * @returns Object of a scheduled week with workers as keys
     */

    async getShiftObjectByWorker(token, year, weekNr) {

        const allShifts = await ShiftsService.getShiftsAllWeek(token, year, weekNr)
        var arrayIds = [];
        var arrayDates = [];
        var filteredArray = [];

        Array.from(allShifts).map((shift) => {

            if (!arrayIds.includes(shift.user_id)) {
                arrayIds.push(shift.user_id)
            }
            if (!arrayDates.includes(arrayDates[i])) {
                arrayDates.push(shift.date)
            }
        })

        arrayIds.map(() => {
            filteredArray.push([])
        })

        for (var i = 0; i < allShifts.length; i++) {

            Array.from(allShifts).map((shift) => {

                if (shift.user_id == arrayIds[i]) {
                    var shifts = [];
                    for (var x = 0; x < 7; x++) {

                        var date = dayjs().year(year).week(weekNr).day(1).add(x, 'day').format('DD-MM-YYYY');

                        shifts.push({
                            date: date,
                            morning: {
                                time_start: null,
                                time_end: null,
                                worker: null,
                                id: null,
                                message: null
                            },
                            afternoon: {
                                time_start: null,
                                time_end: null,
                                worker: null,
                                id: null,
                                message: null
                            },
                            evening: {
                                time_start: null,
                                time_end: null,
                                worker: null,
                                id: null,
                                message: null
                            }
                        })

                        filteredArray[i].shop_name = shift.user_name
                        filteredArray[i].shop_id = shift.user_id
                        // filteredArray[i].scheduling_id = scheduleId
                        filteredArray[i].shifts = shifts
                    }
                }
            })
        }

        Array.from(allShifts).map((shift) => {

            for (var j = 0; j < filteredArray.length; j++) {

                if (shift.user_name == filteredArray[j].shop_name) {

                    Array.from(filteredArray[j].shifts).map((currentShift) => {

                        if (currentShift.date == dayjs(shift.date).format('DD-MM-YYYY') && shift.period == 0) {

                            currentShift.morning.time_start = shift.time_start.substring(0, 5)
                            currentShift.morning.time_end = shift.time_end.substring(0, 5)
                            currentShift.morning.worker = shift.shop_name
                            currentShift.morning.id = shift.shop_id
                            currentShift.morning.message = shift.message
                        }
                        if (currentShift.date == dayjs(shift.date).format('DD-MM-YYYY') && shift.period == 1) {

                            currentShift.afternoon.time_start = shift.time_start.substring(0, 5)
                            currentShift.afternoon.time_end = shift.time_end.substring(0, 5)
                            currentShift.afternoon.worker = shift.shop_name
                            currentShift.afternoon.id = shift.shop_id
                            currentShift.afternoon.message = shift.message
                        }
                        if (currentShift.date == dayjs(shift.date).format('DD-MM-YYYY') && shift.period == 2) {

                            currentShift.evening.time_start = shift.time_start.substring(0, 5)
                            currentShift.evening.time_end = shift.time_end.substring(0, 5)
                            currentShift.evening.worker = shift.shop_name
                            currentShift.evening.id = shift.shop_id
                            currentShift.evening.message = shift.message
                        }
                    })
                }
            }
        })

        localStorage.setItem('workersShiftObject', JSON.stringify(filteredArray))
        console.log('SHIFT OBJECT BY WORKERS: ', filteredArray)
        return filteredArray;
    }

    /**
     * 
     * @param {String} token 
     * @param {Number} year 
     * @param {Number} weekNr 
     * @param {Number} worker Worker id
     * @returns Object of a scheduled week with one worker a key
     */

    async getShiftObjectBySingleWorker(token, year, weekNr, worker) {

        const allShifts = await ShiftsService.getShiftsAllWeek(token, year, weekNr)
        var filteredArray = [];

            Array.from(allShifts).map((shift) => {

                if (shift.user_id == worker) {

                    var shifts = [];
                    for (var x = 0; x < 7; x++) {

                        var date = dayjs().year(year).week(weekNr).day(1).add(x, 'day').format('DD-MM-YYYY');

                        shifts.push({
                            date: date,
                            morning: {
                                time_start: null,
                                time_end: null,
                                worker: null,
                                id: null,
                                message: null
                            },
                            afternoon: {
                                time_start: null,
                                time_end: null,
                                worker: null,
                                id: null,
                                message: null
                            },
                            evening: {
                                time_start: null,
                                time_end: null,
                                worker: null,
                                id: null,
                                message: null
                            }
                        })

                        var newShift = {
                            shop_name : getWorker(worker).full_name,
                            shop_id : shift.user_id,
                            scheduling_id : shift.scheduling_id,
                            shifts : shifts
                        }
                    }

                    filteredArray.push(newShift)
                }
            })

        Array.from(allShifts).map((shift) => {

            for (var j = 0; j < filteredArray.length; j++) {

                if (shift.user_id == worker) {

                    Array.from(filteredArray[j].shifts).map((currentShift) => {

                        if (currentShift.date == dayjs(shift.date).format('DD-MM-YYYY') && shift.period == 0) {

                            currentShift.morning.time_start = shift.time_start.substring(0, 5)
                            currentShift.morning.time_end = shift.time_end.substring(0, 5)
                            currentShift.morning.worker = shift.shop_name
                            currentShift.morning.id = shift.shop_id
                            currentShift.morning.message = shift.message
                        }
                        if (currentShift.date == dayjs(shift.date).format('DD-MM-YYYY') && shift.period == 1) {

                            currentShift.afternoon.time_start = shift.time_start.substring(0, 5)
                            currentShift.afternoon.time_end = shift.time_end.substring(0, 5)
                            currentShift.afternoon.worker = shift.shop_name
                            currentShift.afternoon.id = shift.shop_id
                            currentShift.afternoon.message = shift.message
                        }
                        if (currentShift.date == dayjs(shift.date).format('DD-MM-YYYY') && shift.period == 2) {

                            currentShift.evening.time_start = shift.time_start.substring(0, 5)
                            currentShift.evening.time_end = shift.time_end.substring(0, 5)
                            currentShift.evening.worker = shift.shop_name
                            currentShift.evening.id = shift.shop_id
                            currentShift.evening.message = shift.message
                        }
                    })
                }
            }
        })

        localStorage.setItem('workersShiftObject', JSON.stringify(filteredArray))
        console.log('SHIFT OBJECT BY SINGLE WORKER: ', filteredArray)
        if(filteredArray.length < 1){
            return {
                shop_name : getWorker(worker).full_name,
                shop_id : null,
                scheduling_id : null,
                shifts : null
            }
        }else{
            return filteredArray[0]
        }
    }

}

export default new ShiftsObjects();