<template>
    <div ref="editForm" class="modal-wrapper">
        <div class="edit-shifts-container">
                
                <!-- <pre>
                {{this.setAvailableWorkersList}}
                </pre>   -->

            <div class="shift-form-container">
                <div class="add-worker">   
                    <FormSelect labelText="Operatore" id="worker" v-model:value="worker" :workerSelect="true"
                        :itemList="setAvailableWorkersList" @fullName="fullName" :errors="v$.worker.$errors" />
                </div>
                <div class="add-shift">
                    <FormInput label-text="Data" v-model:value="shiftDate" type="date" :disabled="true" :icon-right="true"
                        icon="calendar-today" :errors="v$.shiftDate.$errors" />
                </div>
                <div class="add-start-time">
                    <FormInput type="time" label-text="Dalle ore" v-model:value="time_start" :icon-right="true" icon="clock"
                        :errors="v$.time_start.$errors" />
                </div>
                <div class="add-end-time">
                    <FormInput type="time" label-text="Alle ore" v-model:value="time_end" :icon-right="true" icon="clock"
                        :errors="v$.time_end.$errors" />
                </div>
                <div class="add-end-time">
                    <FormInput type="text" label-text="Note" v-model:value="message" icon="no-icon" />
                </div>
                <div v-if="timeError" class="time-error error">
                    Gli orari sono sbagliati
                </div>
                <div v-if="scheduleError" class="time-error error">
                    L'operatore è già impegnato in quell'orario

                </div>
            </div>
            <div class="edits-buttons">
                <div class="add">
                    <Button text="Aggiorna" buttonType="successFull" :rounded="true"
                        @click="$emit('editShift', time_start, time_end, worker, shiftDate, message, resource_name)"
                        icon="check" />
                </div>
                <div class="delete">
                    <Button text="Elimina" buttonType="errorFull" :rounded="true"
                        @click="$emit('deleteShift', time_start, time_end, worker, shiftDate, message)" icon="check" />
                </div>
            </div>
            <div class="btn-close" @click="$emit('close')">
                Chiudi
            </div>
        </div>
    </div>
</template>

<script>

import FormInput from '../tools/FormInput.vue';
import FormSelect from '../tools/FormSelect.vue';
import Button from '../tools/Button.vue'
import AvailableUser from '../../services/availableUserService'
import dayjs from 'dayjs';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { getAvailableWorkers } from '../../getters';
import DaysOffService from '../../services/daysOffService';

const ParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(ParseFormat)

export default {
    name: 'edit-shifts',
    components: {
        FormInput,
        FormSelect,
        Button
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    props: {
        morning: {
            default: null,
            type: Object
        },
        afternoon: {
            default: null,
            type: Object
        },
        evening: {
            default: null,
            type: Object
        },
        resource: {
            default: null,
            type: String
        },
        date: {
            default: null,
            type: String
        },
        target: {
            default: null,
            type: String
        },
        timeError: {
            default: false,
            type: Boolean
        },
        scheduleError: {
            default: false,
            type: Boolean
        }
    },

    emits: ['visible', 'editShift', 'deleteShift'],

    data() {
        return {
            // scheduleList,
            availableWorkersList: [],
            workersList: [],
            created: false,
            updated: false,
            period: (this.target == 'morning') ? 0 : (this.target == 'afternoon') ? 1 : 2,
            resource_name: (this.resource !== null) ? this.resource : '',
            time_start: (this.target == 'morning' && this.morning.time_start !== null) ? this.morning.time_start :
                (this.target == 'afternoon' && this.afternoon.time_start !== null) ? this.afternoon.time_start :
                    (this.target == 'evening' && this.evening.time_start !== null) ? this.evening.time_start : '',

            time_end: (this.target == 'morning' && this.morning.time_end !== null) ? this.morning.time_end :
                (this.target == 'afternoon' && this.afternoon.time_end !== null) ? this.afternoon.time_end :
                    (this.target == 'evening' && this.evening.time_end !== null) ? this.evening.time_end : '',

            worker: (this.target == 'morning' && this.morning.worker !== null) ? this.morning.id :
                (this.target == 'afternoon' && this.afternoon.worker !== null) ? this.afternoon.id :
                    (this.target == 'evening' && this.evening.worker !== null) ? this.evening.id : '',

            message: (this.target == 'morning' && this.morning.message !== null) ? this.morning.message :
                (this.target == 'afternoon' && this.afternoon.message !== null) ? this.afternoon.message :
                    (this.target == 'evening' && this.evening.message !== null) ? this.evening.message : '',

            shiftDate: (this.date !== null) ? this.date : '',
        }
    },

    methods: {
        async validate() {
            return await this.v$.$validate()
        },
    },
    computed: {
        parseId() {
            return JSON.stringify(this.id)
        },
        setAvailableWorkersList(){
            return this.availableWorkersList
        }
    },
    validations() {
        return {
            time_start: { required: helpers.withMessage('L\'orario è obbligatorio', required) },
            time_end: {
                required: helpers.withMessage('L\'orario è obbligatorio', required)
            },
            worker: { required: helpers.withMessage('L\'operatore è obbligatorio', required) },
            shiftDate: { required: helpers.withMessage('La data è obbligatoria', required) }
        }
    },
    async mounted() {
        this.workersList = await AvailableUser.getAvailableUser(localStorage.getItem('token'), this.date, this.period)
        var bool = (this.resource_name !== '') ? true : false
        var week = (dayjs(this.date).day() !== 0) ? dayjs(this.date).week() : dayjs(this.date).week() - 1;
        var daysOff = await DaysOffService.getWeekDaysOff(localStorage.getItem('token'), dayjs(this.date).day(6).format('YYYY'), week)
        
        this.availableWorkersList = getAvailableWorkers(this.workersList, daysOff, bool, this.worker, this.date)
    }
}

</script>