<template>
    <div class="main">
        <PageTitle />
        <div class="window">
            <div class="section section--detail">
                <div class="detail-container">
                    <div class="detail-main-container">
                        <WorkerDetailForm ref="workerForm" :options="options" :daysOff="daysOff" :edit="false" :id="false" :listEdit="true"
                            :visible="false" :notesIcons="notesIcons" />
                        <EditButtons toUpdate="Aggiungi operatore" resetText="Reset campi" @validate="validate()"
                            @cancel="reset()" />
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="added" title="Operatore Aggiunto" text="Operatore aggiunto con successo" @redirect="redirect" />
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrollare i dati o riprovare più tardi" @redirect="redirect" />
    </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import WorkerDetailForm from '../../components/forms/WorkerDetailForm.vue';
import EditButtons from '../../components/tools/EditButtons.vue';
import workerService from '../../services/workerService';
import NoteService from '../../services/notesService';
import DaysOffService from '../../services/daysOffService';
import { setWorker } from '../../setters';
import Modal from '../../components/tools/Modal.vue';
import dayjs from 'dayjs';

</script>

<script>

export default {
    name: 'add-workers',
    components: {
        WorkerDetailForm,
        EditButtons,
        PageTitle,
        Modal
    },
    data() {
        return {
            archive: null,
            daysOff: {
                'monday': 0,
                'tuesday': 0,
                'wednesday': 0,
                'thursday': 0,
                'friday': 0,
                'saturday': 0,
                'sunday': 0,
            },
            erase: null,
            error: false,
            edit: null,
            options: {
                // app_access: false,
                address: '',
                badge_number: '',
                city: '',
                email: null,
                gotCar: '',
                id: '',
                lastname: '',
                name: '',
                phone: '',
                totHours: '',
            },
            notes: null,
            added: false,
            notesIcons: false,
        }
    },
    methods: {
        async validate() {
            const workerForm = this.$refs.workerForm
            const isFormValid = await workerForm.validate()

            console.log(isFormValid)
            console.log(workerForm.v$.$error)

            if (isFormValid && !workerForm.v$.$error) {
                this.edit = false

                // WORKER DATA AND CREATE
                var workerData = setWorker(workerForm, 'attivo', 'indeterminato', 'operatore')

                const Worker = await workerService.addWorker(localStorage.getItem('token'), workerData)

                if (Worker !== false) {
                    
                    this.added = true
                    workerData.id = Worker.id

                    // DAYSOFF DATA AND CREATE
                    const daysOffData = workerForm.daysOff
                    daysOffData.week_number = dayjs().week()
                    daysOffData.year = dayjs().day(6).format('YYYY')
                    daysOffData.user_id = Worker.id
                    
                    await DaysOffService.addDayOff(localStorage.getItem('token'), workerForm.daysOff)

                    // NOTES DATA AND CREATE
                    const Notes = workerForm.message

                    Notes.map(async (note) => {

                        if (note !== '') {

                            var noteData = {
                                "message": note,
                                "datetime": dayjs().format('DD/MM/YYYY'),
                                "entity_type": 'user',
                                "user_id": Worker.id,
                                "shop_id": null
                            }

                            await NoteService.addNote(localStorage.getItem('token'), noteData)
                        }

                    })

                    // UPDATE WORKERSLIST IN LOCALSTORAGE
                    var workersList = JSON.parse(localStorage.getItem('workersList'))
                    workersList.push(workerData)
                    localStorage.setItem('workersList', JSON.stringify(workersList))
                }else{
                    this.error = true
                }

            } else {
                return
            }
        },
        reset() {
            // this.$refs.workerForm.app_access = 0
            this.$refs.workerForm.badge_number = ''
            this.$refs.workerForm.name = ''
            this.$refs.workerForm.surname = ''
            this.$refs.workerForm.email = null
            this.$refs.workerForm.phone = ''
            this.$refs.workerForm.address = ''
            this.$refs.workerForm.city = ''
            this.$refs.workerForm.gotCar = 0
            this.$refs.workerForm.totHours = ''
            this.$refs.workerForm.daysOff = {
                    'monday': 0,
                    'tuesday': 0,
                    'wednesday': 0,
                    'thursday': 0,
                    'friday': 0,
                    'saturday': 0,
                    'sunday': 0,
                }
        },
        redirect() {
            this.added = false
            this.error = false
            this.$router.push('/elenco-operatori')
        }
    }
}

</script>