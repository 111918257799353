import axios from "axios"
import Statusservice from './statusService'
import { orderByDate } from '../dataService';
import { endpoint } from "../dataService";

class Export{
    constructor(){
        this.url = `${endpoint()}/api/v1/report`
    }

    async getExports(token, reportData){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json', 
                'Authorization': 'Bearer ' + token,
            },
            data : reportData
        }

        return await axios.get(this.url + '?start=' + reportData.start_date + '&end=' + reportData.end_date + '&type=' + reportData.type + '&id=' + reportData.id, config)
        
        .then(function(response){

            console.log('EXPORT DATA: ', response.data.data)
            var data = response.data.data
            data = data.sort(orderByDate)

            return data

        })
        
        .catch(function (error){

            console.log(error)
            Statusservice.unauthorized(error.response.status)
            return false
        })
    }
}

export default new Export()