<template>
    <div class="main">
        <PageTitle @back="back"/>
        <div class="window">
            <FormSelect v-if="!visible && !edit" class="status-select" labelText="Filtra" :item-list="statusList" v-model:value="status" />
            <!-- <GroupDetail v-if="visible || edit" :options="groups" :listEdit="edit" :notes="notes"/> -->
            <List v-if="!visible && !edit" :item-list="groupsList" @edit="groupsEdit"
                @archive="groupsArchive" @visible="groupsVisible" @active="active" />
        </div>
        <Modal v-if="archive" title="Gruppo archiviato" text="Gruppo archiviato con successo" @redirect="redirect"/>
        <Modal v-if="reactivate" title="Gruppo attivo" text="Gruppo riattivato con successo" @redirect="redirect"/>
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrollare i dati o riprovare più tardi" @redirect="redirect" />
    </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import List from '../../components/groups/List.vue';
import FormSelect from '../../components/tools/FormSelect.vue';
// import GroupDetail from '../../components/GroupDetail.vue';
import GroupService from '../../services/groupService';

import Modal from '../../components/tools/Modal.vue';

</script>

<script>

export default {
    name: 'sales-points-list',
    components: {
        // GroupDetail,
        List,
        FormSelect,
        PageTitle,
    },
    data() {
        return {
            groupsList : [],
            edit: false,
            error: false,
            delete: false,
            archive: false,
            visible: false,
            groups: null,
            reactivate: false,
            status: 'attivo'
        }
    },
    methods: {
        async groupsEdit(bool, item) {
            this.edit = bool
            this.groups = item

            document.querySelector('.page-title').innerHTML = 'Dettaglio punto vendita'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        async groupsArchive(bool, item) {
            this.archive = bool
            this.groups = item

            var groupData = {
                "group_name": item.group_name,
                "status": 'archiviato'
            }
            
            const Group = await GroupService.updateGroup(localStorage.getItem('token'), groupData, item.id) 

            if(Group !== false){

                var groupsList = JSON.parse(localStorage.getItem('groupsList'))

                Array.from(groupsList).map((group) => {
                    if(item.id == group.id){
                        group.status= 'archiviato'
                    }
                })

                localStorage.setItem('groupsList', JSON.stringify(groupsList))
            }else{
                this.error = true
            }
        },
        async active(bool, item) {
            this.reactivate = bool
            this.groups = item

            var groupData = {
                "group_name": item.group_name,
                "status": 'attivo'
            }
            
            const Group = await GroupService.updateGroup(localStorage.getItem('token'), groupData, item.id)      
            
            if(Group !== false){

                var groupsList = JSON.parse(localStorage.getItem('groupsList'))

                Array.from(groupsList).map((group) => {
                    if(item.id == group.id){
                        group.status= 'attivo'
                    }
                })

                localStorage.setItem('groupsList', JSON.stringify(groupsList))
            }else{
                this.error = true
            }
        },
        async groupsVisible(bool, item) {
            this.visible = bool
            this.groups = item

            document.querySelector('.page-title').innerHTML = 'Dettaglio punto vendita'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        back(bool){
            this.edit = bool
            this.visible = bool
            document.querySelector('.page-title').innerHTML = 'Elenco punti vendita'
            document.querySelector('.back-arrow').style.visibility = 'hidden'
        },
        getGroupsList(){
            this.groupsList = JSON.parse(localStorage.getItem('groupsList'))
        },
        redirect(){
            this.archive = false
            this.error = true
            this.reactivate = false
            this.$router.go()
        }
    },
    watch : {
        status(value){
            var list = JSON.parse(localStorage.getItem('groupsList'))
            var filteredList = []
            Array.from(list).map((item) => {
                if(item.status == value){
                    filteredList.push(item)
                }
            })

            if(value !== 'tutti'){
                this.groupsList = filteredList
            }else{
                this.groupsList = JSON.parse(localStorage.getItem('groupsList'))
            }
        }
    },
    computed : {
        statusList(){
            return [
                {full_name : 'Attivi', status : 'attivo'},
                {full_name : 'Archiviati', status : 'archiviato'},
                {full_name : 'Tutti', status : 'tutti'},
            ]
        }
    },
    async mounted(){
        var list = await GroupService.getGroups(localStorage.getItem('token'))
        var filteredList = []
        Array.from(list).map((item) => {
            if(item.status == this.status){
                filteredList.push(item)
            }
        })

        if(this.status !== 'tutti'){
            this.groupsList = filteredList
        }else{
            this.groupsList = list
        }
    }
}

</script>