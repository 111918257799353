<template>
    <div class="modal-wrapper constraints">
        <div class="edit-shifts-container">
            <div class="heading">
                <div class="title">
                    Vincoli
                </div>
            </div>
            <div class="month">{{ month }}</div>
            <div class="constraints-container current-month">
                
                <SingleNotification v-for="(constraint, index) in constraintsList[0]" :key="index" :name="constraint.name"
                    :status="constraint.status" :num_hours="constraint.num_monthly_hours"
                    :tot_hours="constraint.sum_number_hours" />
            </div>
            <div v-if="nextMonth.length > 0" class="month">{{ monthNext }}</div>
            <div v-if="nextMonth.length > 0" class="constraints-container next-month">
                <SingleNotification v-for="(constraint, index) in constraintsList[1]" :key="index" :name="constraint.name"
                    :status="constraint.status" :num_hours="constraint.num_monthly_hours"
                    :tot_hours="constraint.sum_number_hours" />
            </div>
            <div class="btn-close" @click="$emit('close')">
                Chiudi
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs';
import SingleNotification from '../components/notifications/SingleNotification.vue';
import ConstraintsService from '../services/constraintsService';

require('dayjs/locale/it')

const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export default {
    name: 'shop-constraints',
    components: {
        SingleNotification
    },
    props: {
        week_number: {
            default: null,
            type: String
        },
        year: {
            default: null,
            type: String
        },
        scheduleId: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            constraintsList: [],
            currentMonth: [],
            nextMonth: []
        }
    },
    methods: {

        async shopsList() {
            var constraints = []
            var currentShops = []
            var nextShops = []
            this.currentMonth = await ConstraintsService
                .getShopsHours(localStorage.getItem('token'),
                    dayjs().year(this.year).week(this.week_number).day(1).startOf('month').format('YY-MM-DD'),
                    dayjs().year(this.year).week(this.week_number).day(1).endOf('month').format('YY-MM-DD'),
                    'shop', this.scheduleId)
            var shopsList = JSON.parse(localStorage.getItem('shopsList'))

            if (this.sameMonth == false) {
                this.nextMonth = await ConstraintsService
                    .getShopsHours(localStorage.getItem('token'),
                        dayjs().year(this.year).week(this.week_number + 1).startOf('month').format('YY-MM-DD'),
                        dayjs().year(this.year).week(this.week_number + 1).endOf('month').format('YY-MM-DD'),
                        'shop', this.scheduleId)
            }

            constraints = [this.currentMonth, this.nextMonth]

            console.log('vincoli punti vendita: ', constraints)

            Array.from(constraints[0]).map((constraint) => {
                Array.from(shopsList).map((shop) => {
                    if (constraint.shop_id == shop.id) {
                        if ((shop.num_monthly_hours !== null)) {
                            currentShops.push({
                                "name": shop.full_name,
                                "sum_number_hours": constraint.sum_number_hours,
                                "num_monthly_hours": shop.num_monthly_hours,
                                "status": (shop.num_monthly_hours < constraint.sum_number_hours) ? 'more'
                                    : (shop.num_monthly_hours > constraint.sum_number_hours) ? 'less'
                                        : (shop.num_monthly_hours == constraint.sum_number_hours) ? 'ok'
                                            : 'warning'
                            })
                        } else {
                            currentShops.push({
                                "name": shop.full_name,
                                "sum_number_hours": constraint.sum_number_hours,
                                "num_monthly_hours": null,
                                "status": 'warning'
                            })
                        }
                    }
                })
            })

            if (this.sameMonth == false) {
                Array.from(constraints[1]).map((constraint) => {
                    Array.from(shopsList).map((shop) => {
                        if (constraint.shop_id == shop.id) {
                            if ((shop.num_monthly_hours !== null)) {
                                nextShops.push({
                                    "name": shop.full_name,
                                    "sum_number_hours": constraint.sum_number_hours,
                                    "num_monthly_hours": shop.num_monthly_hours,
                                    "status": (shop.num_monthly_hours < constraint.sum_number_hours) ? 'more'
                                        : (shop.num_monthly_hours > constraint.sum_number_hours) ? 'less'
                                            : (shop.num_monthly_hours == constraint.sum_number_hours) ? 'ok'
                                                : 'warning'
                                })
                            } else {
                                nextShops.push({
                                    "name": shop.full_name,
                                    "sum_number_hours": constraint.sum_number_hours,
                                    "num_monthly_hours": null,
                                    "status": 'warning'
                                })
                            }
                        }
                    })
                })
            }

            return [currentShops, nextShops]
        }
    },
    computed: {
        sameMonth() {
            if (dayjs().week(this.week_number).day(1).format('MM') == dayjs().week(this.week_number + 1).day(0).format('MM')) {
                return true
            } else {
                return false
            }
        },
        month() {
            var month = dayjs().week(this.week_number).day(1).locale('it').format('MMMM')
            month = month[0].toUpperCase() + month.substring(1, month.length)
            return month
        },
        monthNext() {
            var month = dayjs().week(this.week_number + 1).day(0).locale('it').format('MMMM')
            month = month[0].toUpperCase() + month.substring(1, month.length)
            return month
        }
    },
    async mounted() {
        this.constraintsList = await this.shopsList()
        console.log(this.constraintsList)
        console.log('lunedì :', dayjs().week(this.week_number).day(1).format('MM'), 'domenica :', dayjs().week(this.week_number + 1).day(0).format('MM'))
    }
}

</script>