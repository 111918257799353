<template>
    <div class="modal-wrapper constraints">
        <div class="edit-shifts-container">
            <div class="heading">
                <div class="title">
                    Vincoli
                </div>
            </div>
            <div class="constraints-container">
                <SingleConstraint v-for="(constraint, index) in constraintsList" :key="index" 
                :name="constraint.user_name" :status="constraint.status" :num_hours="constraint.num_hours" :tot_hours="constraint.tot_hours" />
            </div>
            <div class="btn-close" @click="$emit('close')">
                Chiudi
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs';
import ShiftsService from '../services/shiftsService';
import SingleConstraint from './constraints/SingleConstraint.vue';

const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

export default {
    name : 'verify-constraints',
    components : {
        SingleConstraint
    },
    props : {
        week_number : {
            default : null,
            type : String
        },
        year : {
            default : null,
            type : String
        },
        group_id : {
            default : null,
            type : String
        }
    },
    data(){
        return{
            constraintsList: []
        }
    },
    methods : {
        async totHours(){
            return await ShiftsService.getSingleWorkerWeekTotHours(localStorage.getItem('token'), this.year, this.week_number)
        },
        workersList(){
            var usersList = JSON.parse(localStorage.getItem('workersList'))
            var workersList = []
            Array.from(usersList).map((user) => {
                if(user.role == 'operatore' && user.num_hours !== null){
                    workersList.push(user)
                }
            })
            return workersList
        }
    },
    async mounted(){
        const totHours = await ShiftsService.getSingleWorkerWeekTotHours(localStorage.getItem('token'), this.year, this.week_number)

        var usersList = JSON.parse(localStorage.getItem('workersList'))
        var workersList = []
        Array.from(usersList).map((user) => {
            if(user.role == 'operatore'){
                workersList.push(user)
            }
        })

        this.constraintsList = ShiftsService.checkTotHours(workersList, totHours)
    }
}

</script>