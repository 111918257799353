import axios from "axios"
import StatusService from "./statusService"
import { endpoint } from "../dataService";

class Notes{

    constructor(){

        this.url = `${endpoint()}/api/v1/notes`

    }

    async addNote(token, noteData) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        return await axios.post(this.url, noteData, config)

        .then(function (response) {

            console.log('NOTES DATA: ', response.data.data.id)
            return response.data.data

        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getNotes(token){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url, config)

        .then(function (response) {

            return response.data.data
        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getNote(token, entity, id){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + '/' + entity + '/' + id, config)

        .then(function (response) {

            return response.data.data
        })

        .catch(function (error) {

            console.log(error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async deleteNote(token, id){

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        await axios.delete(this.url + '/' + id, config)

            .then(function (response) {

                console.log('DELETED USER: ', response.data.data)
                return response.data.data

            })

            .catch(function (error) {

                console.log(error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }

    async updateNote(token, id, noteData){

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        await axios.put(this.url + '/' + id, noteData, config)

            .then(function (response) {

                console.log('UPDATED USER: ', response.data.data)
                return response.data.data

            })

            .catch(function (error) {

                console.log(error)
                StatusService.unauthorized(error.response.status)
                return false
            })
    }

}

export default new Notes();