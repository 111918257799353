// Axios
import axios from "axios";
import dayjs from "dayjs";
// import ExtractDate from "./dateService"
import StatusService from "./statusService";
import { endpoint } from "../dataService";

const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

class ScheduleService {

    constructor() {

        this.url = `${endpoint()}/api/v1/schedules/`
    }


    

    ///////////////////////////////////////////////
    // GET ALL SCHEDULES
    //////////////////////////////////////////////

    async getAllSchedules(token) {

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url, config)

        .then(function (response) {

            console.log('SHIFTS: ', response.data.data)

            const comparator = (a, b) => {
                if(dayjs(a.date_start) > dayjs(b.date_start)){
                    return -1
                }else if(dayjs(a.date_start) < dayjs(b.date_start)){
                    return 1
                }
                return 0
            }

            var data = response.data.data

            data = data.sort(comparator)

            return data

        })

        .catch(function (error) {

            console.log('GET ALL SHIFTS ERROR: ', error)
            // StatusService.unauthorized(error.response.status)
            return false
        })
    }

    ///////////////////////////////////////////////
    // FUNCTION TO RETRIEVE NOT SCHEDULED WEEKS
    //////////////////////////////////////////////

    createAvailableWeeksList() {

        var array = []

        for (var i = 0; i < 56; i++) {

            var Monday = dayjs().add(i, 'week').day(1)
            var monday = dayjs(Monday).locale('it').format('ddd DD-MM-YYYY')
            var sunday = dayjs(Monday).add(6, 'day').locale('it').format('ddd DD-MM-YYYY')
            var nrWeek = dayjs().add(i, 'week').week()
            var year = dayjs().add(i, 'week').add(-1, 'month').day(6).format('YYYY')

            array[i] = {
                full_name: monday + ' - ' + sunday,
                id: nrWeek,
                year: year
            }

        }

        for (i = 3; i > 0; i--){

            Monday = dayjs().add(i, 'week').add(-1, 'month').day(1)
            monday = dayjs().add(i, 'week').add(-1, 'month').day(1).locale('it').format('ddd DD-MM-YYYY')
            sunday = dayjs(Monday).add(6, 'day').locale('it').format('ddd DD-MM-YYYY')
            nrWeek = dayjs().add(i, 'week').add(-1, 'month').week()
            year = dayjs().add(i, 'week').add(-1, 'month').day(6).format('YYYY')

            array.unshift({
                full_name: monday + ' - ' + sunday,
                id: nrWeek,
                year: year
            })

        }

        console.log(array.length)

        return array
    }

    schedulableWeeks() {

        var array = this.createAvailableWeeksList()
        console.log('Array: ',array);
        
        const allSchedules = JSON.parse(localStorage.getItem('schedulesList'))
        console.log('ALL: ',allSchedules);

        // var existentWeek = []
        var finalArray = []
        let tempObj = {}

        allSchedules.forEach((element) => {  

            tempObj = {
                "week_number" : element.week_number, 
                "start_date" : dayjs(element.date_start).format('DD-MM-YYYY'), 
                "end_date" : dayjs(element.date_end).format('DD-MM-YYYY'), 
                "year" : parseInt(element.year)
            }
            console.log(tempObj);  

            let found = false;
            for(var i = 0; i < finalArray.length; i++) {
                if ((tempObj.week_number == finalArray[i].week_number)&&(tempObj.year == finalArray[i].year)) {
                    found = true;
                    break;
                }
            }
            if(found == false) {
                finalArray.push(tempObj);
            }
        });
        
        console.log('FINAL: ',finalArray);

        return finalArray
    }

    //////////////////////////////////
    // FUNCTION TO RETRIEVE ALL SHIFTS
    //////////////////////////////////

    async getSschedulesList() {
        const allSschedules = JSON.parse(localStorage.getItem('schedulesList'))
        return allSschedules
    }

    async createSchedule(token, scheduleData) {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        return await axios.post(this.url, scheduleData, config)

        .then(function (response) {

            console.log('SCHEDULE DATA: ', scheduleData)
            return response.data.data
        })

        .catch(function (error) {

            console.log('CREATE SCHEDULE ERROR: ', error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    ////////////////////////////////////
    // FUNCTION TO UPDATE A SINGLE SHIFT 
    ////////////////////////////////////

    async updateSchedule(token, scheduleData, id) {

        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        await axios.put(this.url + id, scheduleData, config)
        

        .then(function (response) {

            console.log('SCHEDULE UPDATED: ', response.data.data)
            return response.data.data

        })

        .catch(function (error) {

            console.log('UPDATE SCHEDULE ERROR: ', error)
            StatusService.unauthorized(error.response.status)
            return false
        })
    }

    async getSingleSchedule(token, id) {

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(this.url + id, config)

        .then(function (response) {

            console.log('SINGLE SCHEDULE: ', response.data.data)
            return response.data.data
        })

        .catch(function (error) {

            console.log('GET SINGLE SCHEDULE ERROR: ' ,error)
            StatusService.unauthorized(error.response.status)
            return false

        })
    }

    async getUsedGroups(token, year, weekNr){

        const config = {
            maxBodyLength: Infinity,
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }

        return await axios.get(`${this.url}${year}/${weekNr}`, config)

        .then(function (response) {

            console.log('USED GROUPS', response.data.data)
            return response.data.data
        })

        .catch(function (error) {

            console.log('GET USED GROUPS ERROR: ', error)
            StatusService.unauthorized(error.response.status)
            return false

        })
    }

    async getAvailableGroups(token, year, weekNr){
        const usedGroups = await this.getUsedGroups(token, year, weekNr)
        const Groups = JSON.parse(localStorage.getItem('groupsList'))
        var availableGroups = []

        if(usedGroups !== false){
            Array.from(Groups).map((group) => {
                if(!usedGroups.includes(group.id)){
                    availableGroups.push(group.id)
                }
            })

            console.log(availableGroups)

            return availableGroups
        }else{
            return false
        }
    }

}

export default new ScheduleService();