<template>
    <div class="section section--detail-list section--detail-list-sales-points section--detail-list-create-shift">
        <div class="detail-list-container">
            <div class="header-list">
                <div class="heading">Nome</div>
                <div class="heading">Gruppo</div>
                <div class="heading">Indirizzo</div>
                <div class="heading">Ispettore</div>
                <div class="heading">Stato</div>
            </div>
            <div v-if="weekChoice" class="select-all-shops">
                <div class="label">Seleziona tutti</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <input ref="selectAll" type="checkbox" :disabled="selectAllDisabled" :checked="all" name="" id="" @change="selectAll(), $emit('checked', selectedShop)" >
            </div>
            <div class="main-list" :class="(!weekChoice) ? 'full' : ''">
                <div class="worker" v-for="(item, index) in itemList" :key="index">
                    <div class="list-item list-item--name">{{ item.full_name }}</div>
                    <div class="list-item list-item--group">{{ groupName(item.group_id) }}</div>
                    <div class="list-item list-item--address">{{ item.address }}</div>
                    <div class="list-item list-item--inspector">{{ inspectorName(item.inspector_id) }}</div>
                    <div class="list-item"></div>
                    <div class="list-item list-item--actions">
                        <input type="checkbox" :value="item.id" v-model="selectedShop" :selected="selectedShopsList(shiftShops, item.id)" name="" id="" @change="updateList(item.id), $emit('checked', selectedShop)" >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal v-if="deleting" :cancel="true" title="Vuoi eliminare il punto vendita dalla schedulazione?" @cancel="cancel" @redirect="deleteShop"/>
</template>

<script setup>

// import workersList from '../models/workers.json'
// import dayjs from 'dayjs';
// import FormInput from '../tools/FormInput.vue';
import StorePlanningService from '../../services/storePlanningService';
import Modal from '../tools/Modal.vue';
import { getGroup, getWorker } from '../../getters';

</script>

<script>

export default {
    name: 'create-list',
    components: {
        Modal
    },
    props: {
        itemList: {
            default: null,
            type: Object
        },
        shiftShops : {
            default : null,
            type : Array
        },
        scheduleId : {
            default : null,
            type : Number
        },
        weekChoice : {
            default: true,
            type: Boolean
        },
        selectAllDisabled : {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            all: false,
            deleting: false,
            pageNr: 0,
            selectedShop : [],
            shopId : null,
        }
    },
    computed: {
        
    },
    emits: ['checked'],
    methods: {
        async updateList(id){
            if(!this.selectedShopsList(this.selectedShop, id) && this.scheduleId !== null){
                this.shopId = id
                this.deleting = true
            }

            localStorage.setItem('createShiftList', JSON.stringify(this.selectedShop))
        },
        selectedShopsList(array, id){
            if(array == null || array.error == true){
                return false
            }else{
                if(array.includes(id)){
                    return true
                }else{
                    return false
                }
            }
        },
        selectAll(){
            if(this.all == true){
                this.selectedShop = []
                this.all = false 
            } else { 
                Array.from(this.itemList).map((item) => {
                    if(!Array.from(this.selectedShop).includes(item.id)){
                        this.selectedShop.push(item.id)
                    }
                })
                this.all = true 
            }
            localStorage.removeItem('createShiftList')
            localStorage.setItem('createShiftList', JSON.stringify(this.selectedShop))
        },
        cancel(){
            this.deleting = false
        },
        async deleteShop(){

            var scheduleData = {
                "scheduling_id" : this.scheduleId,
                "shop_id" : this.shopId
            }

            await StorePlanningService.deleteScheduleShop(localStorage.getItem('token'), scheduleData)

            this.$router.go()
        },
        groupName(id){
            var group = getGroup(id)
            if(getGroup(id)){
                return group.group_name
            }else{
                return ''
            }
        },
        inspectorName(id){
            var inspector = getWorker(id)
            if(getWorker(id)){
                return inspector.full_name
            }else{
                return ''
            }
        }
    },
    watch : {
        shiftShops(){
            setTimeout(() => {
                if(this.shiftShops !== null) this.selectedShop = this.shiftShops
                localStorage.removeItem('createShiftList')
                localStorage.setItem('createShiftList', JSON.stringify(this.selectedShop))
            }, 500)
        },
        itemList(){
            this.selectedShop = []
        },
        selectedShop(value){
            if(value !== null && value.length == 0){
                this.all = false
            }
        }
    },
    mounted() {
        localStorage.removeItem('createShiftList')
            setTimeout(() => {
                this.selectedShop = this.shiftShops
                localStorage.setItem('createShiftList', JSON.stringify(this.selectedShop))
            }, 500)
    }
}

</script>