<template>
    <div ref="accordion" class="accordion">
        <div ref="title" class="accordion-title" @click="clickAction()">
            <div class="title">{{ title }}</div>
            <div class="icon-container">
                <svg :class="iconClass">
                    <use :xlink:href="iconId"></use>
                </svg>
            </div>
        </div>
        <div ref="body" class="accordion-hidden-section">
            <div ref="accordionList" class="accordion-list">
                <ul>
                    <div @click="closeMenu()" v-for="(child, index) in children" :key="index" class="list-item">
                       <router-link :to="child[1]">{{ child[0] }}</router-link> 
                    </div>
                </ul>
            </div>
        </div>
    </div>
    
</template>

<script>

export default {
    name: 'menu-accordeon',
    props: {
        title: {
            default: 'Item',
            type: String
        },
        children: {
            default: null,
            type: Array
        },
        icon : {
            default : '',
            type : String
        },
    },
    // data(){
    //     return{
    //         selected: null
    //     }
    // },
    methods: {
        clickAction() {
            if (this.$refs.accordion.classList.contains('open')) {
                this.$refs.accordion.classList.remove('open')
                this.$refs.body.style.height = '0px'
            } else {
                this.$refs.accordion.classList.toggle('open')
                this.$refs.body.style.height = this.$refs.accordionList.offsetHeight + 'px'
            }
        },

        reset() {
            this.$refs.accordion.classList.remove('open')
            this.$refs.body.style.height = '0px'
        },

        destroy() {
            this.$refs.title.removeEventListener('click', this.clickAction)
            this.reset()
        },
        closeMenu(){
            if(window.innerWidth < 768){
                document.querySelector('.app-container').classList.remove('menu-open')
                document.querySelector('.menu-container').style.display = 'none'
            }
        }
    },

    computed : {
        iconId(){
            return '#' + this.icon
        },
        iconClass(){
            return 'icon icon-' + this.icon
        },
    },

    emits : ['pageTitle'],
    mounted() {
        this.reset()
    },

}

</script>