<template>
    <div class="header">
        <div class="icon-container open-menu">
            <svg class="iconClass">
                <use xlink:href="#hamburger"></use>
            </svg>
            <router-link :to="'/'" class="dashboard-link">Informitalia</router-link>
        </div>
        <div class="aside-header">
            <div class="icon-container log-out">
                <router-link :to="'/notifiche'">
                    <svg class="icon icon-notification">
                        <use xlink:href="#notifications"></use>
                    </svg>
                </router-link>
                <div v-if="number > 0" class="number">{{ number }}</div>
            </div>
            <div class="icon-container log-out" @click="logout()">
                <svg class="icon icon-share icon--rotate-90">
                    <use xlink:href="#share"></use>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
import ConstraintsService from '../services/constraintsService'

export default {
    name: 'app-header',
    props: {
        dashboard: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            number: 0
        }
    },
    methods: {
        logout() {
            localStorage.clear()
            this.$router.go()
        },
    },
    watch: {
        async number(value) {

            var shops = []
            var constraints = await ConstraintsService
                .getShopsHours(localStorage.getItem('token'),
                    dayjs().startOf('month').format('YY-MM-DD'),
                    dayjs().endOf('month').format('YY-MM-DD'),
                    'shop', 0)
            var shopsList = JSON.parse(localStorage.getItem('shopsList'))

            Array.from(constraints).map((constraint) => {
                Array.from(shopsList).map((shop) => {
                    if (constraint.shop_id == shop.id) {
                        if ((shop.num_monthly_hours !== null && constraint.sum_number_hours > shop.num_monthly_hours)) {
                            shops.push({
                                "name": shop.full_name,
                                "sum_number_hours": constraint.sum_number_hours,
                                "num_monthly_hours": shop.num_monthly_hours,
                                "status": 'more'
                            })
                        }
                    }
                })
            })

            if (value !== shops.length) {
                this.number = shops.length
            }
        }
    },
    async mounted() {
        if (localStorage.getItem('token')) {
            var shops = []
            var constraints = await ConstraintsService
                .getShopsHours(localStorage.getItem('token'),
                    dayjs().startOf('month').format('YY-MM-DD'),
                    dayjs().endOf('month').format('YY-MM-DD'),
                    'shop', 0)
            var shopsList = JSON.parse(localStorage.getItem('shopsList'))

            Array.from(constraints).map((constraint) => {
                Array.from(shopsList).map((shop) => {
                    if (constraint.shop_id == shop.id) {
                        if ((shop.num_monthly_hours !== null && constraint.sum_number_hours > shop.num_monthly_hours)) {
                            shops.push({
                                "name": shop.full_name,
                                "sum_number_hours": constraint.sum_number_hours,
                                "num_monthly_hours": shop.num_monthly_hours,
                                "status": 'more'
                            })
                        }
                    }
                })
            })

            this.number = shops.length
        }

        document.querySelector('.close-menu').addEventListener('click', function () {
            console.log('close')
            document.querySelector('.menu-container').style.display = 'none'
            document.querySelector('.app-container').classList.remove('menu-open')
        })

        document.querySelector('.open-menu .iconClass').addEventListener('click', function () {
            console.log('open')
            document.querySelector('.menu-container').style.display = 'block'
            document.querySelector('.app-container').classList.add('menu-open')
        })
    }
}

</script>