<template>
    <div class="main">
        <PageTitle @back="back"/>
        <div class="window">
            <FormSelect v-if="!visible && !edit" class="status-select" labelText="Filtra" :item-list="statusList" v-model:value="status" />
            <SalesPointsDetail v-if="visible || edit" :options="salesPoints" :listEdit="edit" :notes="notes"/>
            <List v-if="!visible && !edit" :item-list="salesPointsList" @edit="salesPointsEdit" @delete="salesPointsDelete"
                @archive="salesPointsArchive" @visible="salesPointsVisible" @active="active" />
        </div>
        <Modal v-if="archive" title="Punto vendita archiviato" text="Punto vendita archiviato con successo" @redirect="redirect"/>
        <Modal v-if="reactivate" title="Punto vendita attivo" text="Punto vendita riattivato con successo" @redirect="redirect"/>
    </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import List from '../../components/sales-points/List.vue';
import SalesPointsDetail from '../../components/SalesPointsDetail.vue';
import FormSelect from '../../components/tools/FormSelect.vue';
import ShopService from '../../services/shopService';
import { setShop } from '../../setters';
import Modal from '../../components/tools/Modal.vue';

</script>

<script>

export default {
    name: 'sales-points-list',
    components: {
        SalesPointsDetail,
        List,
        PageTitle,
        FormSelect
    },
    data() {
        return {
            salesPointsList : [],
            edit: false,
            error: false,
            delete: false,
            archive: false,
            visible: false,
            salesPoints: null,
            reactivate: false,
            notes: [],
            status: 'attivo'
        }
    },
    methods: {
        salesPointsDelete(bool, item) {
            this.delete = bool
            this.salesPoints = item
        },
        async salesPointsEdit(bool, item, notes) {
            this.edit = bool
            this.salesPoints = item
            this.notes = await notes

            document.querySelector('.page-title').innerHTML = 'Dettaglio punto vendita'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        async salesPointsArchive(bool, item) {

            this.archive = bool
            this.salesPoints = item

            var shopData = setShop(item, 'archiviato', item.inspector_name)
            
            const Shop = await ShopService.updateShop(localStorage.getItem('token'), shopData, item.id)
            
            if(Shop !== false){

                var shopsList = JSON.parse(localStorage.getItem('shopsList'))

                Array.from(shopsList).map((shop) => {

                    if(item.id == shop.id){
                        shop.status = "archiviato"
                    }
                })

                localStorage.setItem('shopsList', JSON.stringify(shopsList))
            }else{
                this.error = true
            }
        },
        async active(bool, item) {
            this.reactivate = bool
            this.salesPoints = item

            var shopData = setShop(item, 'attivo', item.inspector_name)
            
            const Shop = await ShopService.updateShop(localStorage.getItem('token'), shopData, item.id)   
            
            if(Shop !== false){

                var shopsList = JSON.parse(localStorage.getItem('shopsList'))

                Array.from(shopsList).map((shop) => {

                    if(item.id == shop.id){
                        shop.status = "attivo"
                    }
                })

                localStorage.setItem('shopsList', JSON.stringify(shopsList))
            }else{
                this.error = true
            }
        },
        async salesPointsVisible(bool, item, notes) {
            this.visible = bool
            this.salesPoints = item
            this.notes = await notes

            document.querySelector('.page-title').innerHTML = 'Dettaglio punto vendita'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        back(bool){
            this.edit = bool
            this.visible = bool
            document.querySelector('.page-title').innerHTML = 'Elenco punti vendita'
            document.querySelector('.back-arrow').style.visibility = 'hidden'
        },
        getSalesPointsList(){
            this.salesPointsList = JSON.parse(localStorage.getItem('shopsList'))
        },
        redirect(){
            this.archive = false
            this.error = false
            this.reactivate = false
            this.$router.go()
        }
    },
    watch : {
        status(value){
            var list = JSON.parse(localStorage.getItem('shopsList'))
            var filteredList = []
            Array.from(list).map((item) => {
                if(item.status == value){
                    filteredList.push(item)
                }
            })

            if(value !== 'tutti'){
                this.salesPointsList = filteredList
            }else{
                this.salesPointsList = JSON.parse(localStorage.getItem('shopsList'))
            }
        }
    },
    computed : {
        statusList(){
            return [
                {full_name : 'Attivi', status : 'attivo'},
                {full_name : 'Archiviati', status : 'archiviato'},
                {full_name : 'Tutti', status : 'tutti'},
            ]
        }
    },
    async mounted(){
        
        var list = await ShopService.getShops(localStorage.getItem('token'))
        localStorage.setItem('shopsList', JSON.stringify(list))
        var filteredList = []
        Array.from(list).map((item) => {
            if(item.status == this.status){
                filteredList.push(item)
            }
        })

        if(this.status !== 'tutti'){
            this.salesPointsList = filteredList
        }else{
            this.salesPointsList = list
        }
    }
}

</script>