<template>
    <div :id="resource" class="calendar-day" :class="day" @click="$emit('shiftTime', $event.target)">
        <div class="morning-shift-container" :class="event(morning.worker)">
            <div v-if="morning" class="morning-shift">
                <div class="worker">
                    {{ morning.worker }}
                </div>
                <div v-if="morning.time_start !== null" class="shift">
                    {{ morning.time_start + ' - ' +  morning.time_end }}
                </div>
                <div v-if="morning.message !== null && morning.message !== '' && morning.message !== undefined" class="message-notification"></div>
            </div>
        </div>
        <div class="afternoon-shift-container" :class="event(afternoon.worker)">
            <div v-if="afternoon" class="afternoon-shift">
                <div class="worker">
                    {{ afternoon.worker }}
                </div>
                <div v-if="afternoon.time_start !== null" class="shift">
                    {{ afternoon.time_start + ' - ' +  afternoon.time_end }}
                </div>
                <div v-if="afternoon.message !== null && afternoon.message !== '' && afternoon.message !== undefined" class="message-notification"></div>
            </div>
        </div>
        <div class="evening-shift-container" :class="event(evening.worker)">
            <div v-if="evening" class="evening-shift">
                <div class="worker">
                    {{ evening.worker }}
                </div>
                <div v-if="evening.time_start !== null" class="shift">
                    {{ evening.time_start + ' - ' +  evening.time_end }}
                </div>
                <div v-if="evening.message !== null && evening.message !== '' && evening.message !== undefined" class="message-notification"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Scheduler-Shift',
    props: {
        day: {
            default: null,
            type: String
        },
        resource: {
                default: null,
                type: String
        },
        morning: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        },
        afternoon: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        },
        evening: {
            worker: {
                default: null,
                type: String
            },
            time_start: {
                default: null,
                type: String
            },
            
            time_end: {
                default: null,
                type: String
            }
        }
    },
    emits: ['shiftTime'],
    methods: {
        event(elem){
            if(elem !== null){
                return ' event';
            }else{
                return '';
            }
        },
    }
}

</script>