<template>
    <div class="section section--form-detail">
        <div class="form-container">
            <FormInput labelText="Nome Gruppo*" :disabled="(listEdit) ? false : true" v-model:value="group_name" :errors="v$.group_name.$errors" />
        </div>
    </div>
</template>

<script>

import FormInput from '../tools/FormInput.vue';
// import Groups from '../../services/groupService';

import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
// import dayjs from 'dayjs';

export default {
    name : 'sales-points-detail-form',
    components : {
        FormInput
    },
    setup(){
        return{
            v$: useVuelidate()
        }
    },
    data(){
        return{
            group_name : (this.options.group_name) ? this.options.group_name : '',
        }
    },
    props : {
        edit : {
            default : false,
            type : Boolean
        },
        options : {
            group_name : {
                default : null,
                type : String
            },
        },
        listEdit :{
            default : null,
            type : Boolean
        },
        visible : {
            default : true,
            type : Boolean
        }
    },
    methods: {
        async validate(){
            return await this.v$.$validate()
        }
    },
    validations () {
        return {
            group_name: { required: helpers.withMessage('Il nome è obbligatorio', required) },
        }
    },
    // 
    
    computed: {
        
    },
    async mounted(){
        // await this.getGroups()
    }
}

</script>