<template>
    <div class="section section--detail">
        <div class="detail-container">

            <!-- Form -->
            <div class="detail-main-container">
                <InspectorDetailForm ref="inspectorForm" :options="options" :edit="edit" :notes="notes" :listEdit="edit" />
                <EditButtons v-if="edit" toUpdate="Aggiorna ispettore" @cancel="editsCancel" resetText="Annulla modifiche"
                    @validate="validate()" />
            </div>

            <!-- Side bar -->
            <div class="detail-aside-container">
                <AsideDetail @edit="workerEdit" @archive="workerArchive" @delete="workerDelete" @active="active" :edit="edit" :status="options.status" subject="ispettore"/>
            </div>
        </div>

        <!-- Modals -->
        <ProfileRemover v-if="erase" text="Elimina operatore" :profile="options" toDelete="l'operatore"
            @cancel="workerDelete" />
        <Modal v-if="archive" title="Ispettore archiviato" text="Ispettore archiviato con successo" @redirect="redirect"/>
        <Modal v-if="updated" title="Ispettore aggiornato" text="Ispettore aggiornato con successo" @redirect="redirect"/>
        <Modal v-if="reactivate" title="Ispettore ripristinato" text="Ispettore ripristinato con successo" @redirect="redirect"/>
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrolla i dati o riprova più tardi" @redirect="redirect" />
    </div>
</template>

<script setup>

import InspectorDetailForm from './forms/InspectorDetailForm.vue';
import AsideDetail from './AsideDetail.vue';
import EditButtons from './tools/EditButtons.vue';
import ProfileRemover from './tools/ProfileRemover.vue';
import WorkerService from '../services/workerService';
import { setWorker } from '../setters';
import Modal from './tools/Modal.vue';


</script>

<script>

export default {
    name: 'inspector-details',
    components: {
        InspectorDetailForm,
        AsideDetail,
        EditButtons,
        ProfileRemover,
        Modal
    },
    props: {
        options: {
            default: null,
            type: Object
        },
        notes : {
            default: null,
            type: Array
        },
        listEdit: {
            default: null,
            type: Boolean
        }
    },
    data() {
        return {
            archive: null,
            edit: (this.listEdit) ? true : false,
            erase: null,
            error: false,
            reactivate: false
        }
    },
    methods: {
        async workerArchive(bool) {
            this.archive = bool

            var workerData = setWorker(this.options, 'archiviato', null, 'ispettore')

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.options.id)

            if(Worker !== false){

                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {

                    if(this.options.id == worker.id){
                        worker.status = 'archiviato'
                    }
                })

                localStorage.setItem('workersList', JSON.stringify(workersList))
            }else{
                this.error = true
            }
        },
        async active(bool) {

            this.reactivate = bool

            var workerData = setWorker(this.options, 'attivo', null, 'ispettore')

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.options.id)

            if(Worker !== false){

                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {

                    if(this.options.id == worker.id){
                        worker.status = 'attivo'
                    }

                })

                localStorage.setItem('workersList', JSON.stringify(workersList))
            }else{
                this.error = true
            }
        },
        workerEdit(bool) {
            this.edit = bool
        },
        workerDelete(bool) {
            this.erase = bool
        },
        editsCancel(bool) {
            this.edit = bool
            this.$router.go()
        },
        async validate() {

            const inspectorForm = this.$refs.inspectorForm
            const isFormValid = inspectorForm.validate()
            
            if(isFormValid && !inspectorForm.v$.$error){

                this.edit = false
                this.updated = true

                var workerData = setWorker(inspectorForm, 'attivo', null, 'ispettore')

                const Worker = await WorkerService.setWorker(localStorage.getItem('token'), workerData, this.options.id)

                if(Worker !== false){

                    var workersList = JSON.parse(localStorage.getItem('workersList'))

                    Array.from(workersList).map((worker) => {
                        if(this.options.id == worker.id){
                            worker = setWorker(inspectorForm, 'attivo', null, 'ispettore')
                        }
                    })

                    localStorage.setItem('workersList', JSON.stringify(workersList))
                }else{
                    this.error = true
                }

            }else{
                return
            }
        },
        redirect(){
            
            this.archive = false
            this.updated = false
            this.reactivate = false
            this.$router.go()
        }
    }
}

</script>