<template>
    <div class="select-container">
        <label v-if="labelText !== null" :for="name">
            {{ labelText }}
        </label>

        <select :disabled="disabled" v-if="months" name="" id="" @change="$emit('update:value', $event.target.value)">
            <option value>--</option>
            <option v-for="(month, index) in monthList" :key="index" :value="month[0]">{{ month[1] }}</option>
        </select>

        <select :disabled="disabled" v-if="week" name="" id="" :value="value" @change="$emit('update:value', $event.target.value)">
            <option v-for="i in 7" :key="i" :value="i" :selected="(i == value) ? true : false">{{ dayjs().day(i).locale('it').format('dddd') }}</option>
        </select>

        <select :disabled="disabled" v-if="workerSelect" name="" ref="workerSelect" id="" @change="$emit('update:value', $event.target.value)">
            <option value> -- </option>
            <option v-for="(item, j) in itemList" :key="j" :ref="'item-' + item.id" :value='item.id' :selected="(value == item.id) ? 'selected' : false">{{ (item.full_name) ? item.full_name : item.group_name }}</option>
        </select>

        <select :disabled="disabled" v-if="weeksList" name="" id="" @change="$emit('update:value', $event.target.value), first()">
            <option value :disabled="firstDisabled"> -- </option>
            <option v-for="(item, j) in itemList" :key="j" :ref="'item-' + item.id" :value='JSON.stringify({ "weekNr" : item.id, "year" : item.year })'>{{ item.full_name}}</option>
        </select>

        <select :disabled="disabled" v-if="scheduleList" name="" id="" @change="$emit('update:value', $event.target.value), first()">
            <option ref="first" value :disabled="firstDisabled"> -- </option>
            <option v-for="(item, j) in itemList" :key="j" :value='JSON.stringify({"week_number": item.week_number, "year": item.year, "start_date": item.start_date, "end_date": item.end_date})' :selected="(value == item.week_number) ? 'selected' : false">{{ item.start_date + ' - ' + item.end_date }}</option>
        </select>

        <select :disabled="disabled" v-if="!months && !week && !workerSelect && !weeksList && !scheduleList && !dashboardSelect" name="" id="" @change="$emit('update:value', $event.target.value)">
            <option v-for="(item, j) in itemList" :key="j" :ref="'item-' + item.id" :value="item.status" :selected="((j == 0) ? 'selected' : false)">{{ item.full_name}}</option>
        </select>

        <select :disabled="disabled" v-if="dashboardSelect" name="" id="" @change="$emit('update:value', $event.target.value)">
            <option v-for="(item, j) in itemList" :key="j" :ref="'item-' + item.group_id" :value="item.group_id">{{ item.group_name}}</option>
        </select>
        
        <div class="icon-container">
            <svg class="icon icon-chevron-left">
                <use xlink:href="#chevron-left"></use>
            </svg>
        </div>

        <div v-for="error of errors" :key="error.$uid" class="error">
            {{ error.$message }}
        </div>
    </div>
</template>

<script setup>

import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
// import locale from 'dayjs/locale/it'

dayjs.extend(dayOfYear)
require('dayjs/locale/it')
 
</script>

<script>

export default {
    name : 'form-select',
    props : {
        name : {
            default : '',
            type : String
        },
        labelText : {
            default : null,
            type : String,
        },
        icon : {
            default : null,
            type : String
        },
        months : {
            default : false,
            type : Boolean
        },
        week : {
            default : false,
            type : Boolean
        },
        workerSelect : {
            default : false,
            type: Boolean
        },
        weeksList : {
            default : false,
            type: Boolean
        },
        scheduleList : {
            default : false,
            type: Boolean
        },
        disabled : {
            default : false,
            type : Boolean
        },
        value : {
            default : '',
            type : String
        },
        itemList : {
            default : null,
            type : Array
        },
        errors : {
            default : null,
            type : String
        },
        dashboardSelect : {
            default : false,
            type: Boolean
        }
    },
    data(){
        return{
            firstDisabled: false
        }
    },
    emits : ['update:value', 'fullName'],
    computed : {
        monthList(){
            var dateList = [];
            for(var i = 0; i < 12; i++){
                dateList.push([dayjs(new Date()).add(i, 'month').locale('it').format('YYYY-MM'), dayjs(new Date()).add(i, 'month').locale('it').format('MMMM YY')]);
            }
            return dateList
        },
        iconId(){
            return '#' + this.icon
        },
        iconClass(){
            return 'icon icon-' + this.icon
        },
    },
    methods:{
        first(){
            this.firstDisabled = true
        }
    }
}

</script> 