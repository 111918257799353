<template>
    <div class="section section--form-detail">
        <FormInput v-if="id"  id="id" name="id" labelText="Id" :disabled="true" :value="options.id"/>
        <div class="form-container">
            <FormInput labelText="Nome*" :disabled="(listEdit) ? false : true" v-model:value="name" :errors="v$.name.$errors"/>
            <FormInput labelText="Cognome*" :disabled="(listEdit) ? false : true" v-model:value="surname" :errors="v$.surname.$errors"/>
            
            <FormInput type="email" labelText="Email" :disabled="(listEdit) ? false : true" v-model:value="email" :errors="v$.email.$errors"/>
            <FormInput type="tel" labelText="Telefono" :disabled="(listEdit) ? false : true" v-model:value="phone" :errors="v$.phone.$errors"/>
            <FormInput labelText="Indirizzo" :disabled="(listEdit) ? false : true" v-model:value="address"/>
            <FormInput labelText="Città" :disabled="(listEdit) ? false : true" v-model:value="city"/>
        </div>
        <div class="notes-container">
            <div class="title">Note</div>
            <Button v-if="listEdit" class="add-notes" id="add" buttonType="brand" rounded="true" text="Aggiungi una nota" icon="close" status="" @click="addNoteField()"/>
        </div>
        <Notes ref="notesField" v-for="(note, index) in formNotes" :id="index" :disabled="(listEdit) ? false : true" 
        @deleteNote="deleteNote(index)" @saveNote="saveNote(index)" @editNote="editNote(index)" :icons="icons(index)" :visible="(!listEdit) ? false : true"
        icon="delete" :key="index" v-model:value="message[index]" class="note-field" />
    </div>
</template>

<script>

import FormInput from '../tools/FormInput.vue';
import Button from '../tools/Button.vue';
import Notes from '../Notes.vue';
import NotesService from '../../services/notesService';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import dayjs from 'dayjs';

const valid_phone = helpers.regex(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\|\s)?(\d{3})(\|\s)?(\d{3,6})$/)

export default {
    name : 'inspector-details-form',
    setup(){ 
        return{
            v$: useVuelidate()
        }
     },
    components : {
        FormInput,
        Notes,
        Button
    },
    data(){
        return{
            address : (this.options.address !== '') ? this.options.address : '',
            city : (this.options.city !== '') ? this.options.city : '',
            email : (this.options.email !== '') ? this.options.email : '',
            formNotes :  null,
            message : [],
            name : (this.options.name !== '') ? this.options.name : '',
            phone : (this.options.phone !== '') ? this.options.phone : '',
            surname : (this.options.surname !== '') ? this.options.surname : '',
        }
    },
    props : {
        edit : {
            default : false,
            type : Boolean
        },
        listEdit : {
            default : null,
            type : Boolean
        },
        id : {
            deafult : true,
            type : Boolean
        },
        options : {
            name : {
                default : '',
                type : String
            },
            surname : {
                default : '',
                type : String
            },
            email : {
                default : '',
                type : String
            },
            phone : {
                default : '',
                type : String
            },
            address : {
                default : '',
                type : String
            },
            city : {
                default : '',
                type : String
            },
        },
        scope : {
            deafult : '',
            type : String
        },
        notes : {
            default : null,
            type : Array
        },
        visible : {
            default : true,
            type : Boolean
        }
    },
    methods: {
        async validate(){
            return await this.v$.$validate()
        },
        addNoteField(){
            this.formNotes++
            this.message.push([])
        },
        async deleteNote(index){
            
            await NotesService.deleteNote(localStorage.getItem('token'), this.notesList[index].id)

            // this.notesList.splice(index, 1)
            // this.message.splice(index, 1)
        },
        async saveNote(index){

            var noteData = {
                "message" : this.message[index],
                "datetime" : dayjs().format('DD/MM/YYYY'),
                "entity_type" : 'user',
                "user_id" : this.options.id,
                "shop_id" : null
            }

            var newNote = await NotesService.addNote(localStorage.getItem('token'), noteData)

            this.notesList.push(newNote)
        },
        async editNote(index){

            var noteData = {
                "message" : this.message[index],
                "datetime" : dayjs().format('DD/MM/YYYY'),
                "entity_type" : 'user',
                "user_id" : this.options.id,
                "shop_id" : null
            }

            this.notesList[index].message = this.message[index]

            await NotesService.updateNote(localStorage.getItem('token'), this.notes[index].id, noteData)

            var textarea = document.querySelectorAll('.note-field')[index]

            textarea.classList.add('blur')

            setTimeout(() => {
                textarea.classList.remove('blur')
            }, 1500)

        },
        icons(index){
            if(this.notes !== null && this.notes.length > 0){
                if(this.notes[index]){
                    return 1
                }else{
                    return 2
                }
            }
        },
    },
    computed : {
        notesList(){
            return this.notes
        }
    },
    watch : {
        notes(value){
            if(value !== null && value.length > 0){
                this.message = []
                value.map((note) => {
                    this.message.push(note.message)
                })
                this.formNotes = value.length
            }else{
                this.message = []
                this.formNotes = 1
            }

            this.notesList = value
        }
    },
    validations () {
        return {
            name: { required: helpers.withMessage('Il nome è obbligatorio', required) },
            surname : { required: helpers.withMessage('Il cognome è obbligatorio', required) },
            email : {
                email : helpers.withMessage('L\'email inserita non è corretta', email)
            },
            phone : {
                validPhone : helpers.withMessage('Il numero inserito non è corretto', valid_phone)
            }
        }
    },
    mounted(){
        if(this.notesList !== null && this.notesList.length > 0){
                this.notesList.map((note) => {
                    this.message.push(note.message)
                })
                this.formNotes = this.notesList.length
            }else{
                this.message = []
                this.formNotes = 1
            }
    }
}

</script>