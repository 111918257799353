<template>
    <div class="edit-buttons-container">
        <Button :text="update" buttonType="successFull" icon="check" :rounded="true" @click="$emit('validate')" />
        <Button :text="resetText" buttonType="error" icon="delete" :rounded="true" @click="$emit('cancel', false)" />
    </div>
</template>

<script setup>

import Button from './Button.vue';

</script>

<script>

export default {
    name : 'edit-buttons',
    components : {
        Button
    },
    props : {
        toUpdate : {
            default : null,
            type : String
        },
        resetText : {
            default : null,
            type : String
        }
    },
    emits : ['cancel', 'validate'],
    computed : {
        update(){
            if(this.toUpdate !== null){
                return this.toUpdate
            }else{
                return ''
            }
        }
    }
}

</script>