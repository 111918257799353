<template>
    <div class="detail-aside">
        <div class="aside-btn aside-btn--edit" @click="$emit('edit', true)">
            <div class="icon-container">
                <svg class="icon icon-edit_square" :class="(edit) ? 'success' : ''">
                    <use xlink:href="#edit-square"></use>
                </svg>
            </div>
            <div v-if="edit" class="text success">Modifica attiva</div>
            <div v-else class="text">Abilita Modifica</div>
        </div>
        <div v-if="status == 'attivo'"  class="aside-btn aside-btn--archive" @click="$emit('archive', true)">
            <div class="icon-container">
                <svg class="icon icon-folder">
                    <use xlink:href="#folder"></use>
                </svg>
            </div>
            <div class="text warning">Archivia {{ subject }}</div>
        </div>
        <div v-else  class="aside-btn aside-btn--archive" @click="$emit('active', true)">
            <div class="icon-container">
                <svg class="icon icon-folder">
                    <use xlink:href="#reset"></use>
                </svg>
            </div>
            <div class="text warning">Ripristina {{ subject }}</div>
        </div>
        <!--<div class="aside-btn aside-btn--delete" @click="$emit('delete', true)">
            <div class="icon-container">
                <svg class="icon icon-delete">
                    <use xlink:href="#delete"></use>
                </svg>
            </div>
            <div class="text error">Elimina operatore</div>
        </div>-->
    </div>
</template>

<script>

export default {
    name : 'detail-aside',
    props  : {
        edit : {
            default : false,
            type : Boolean
        },
        subject : {
            default : 'operatore',
            type : String
        },
        status : {
            default : 'archiviato',
            type: String
        }
    },
    emits : ['edit', 'delete', 'archive']
}

</script>