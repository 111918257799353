<template>
    <div class="create-shifts-form">
        <div v-if="weekChoice" class="section section--form section--form-create-shift">
            <div class="title">
                Seleziona la settimana per il turno
            </div>
            <div class="form-container">
                <FormSelect :weeksList="true" :item-list="weeksList" labelText="Settimana" v-model:value="week" />
                <div class="week-display" v-if="isWeekSelected">Settimana nr. {{ weekObject.weekNr }}</div>
                <div v-if="weekError" class="week-error error">Non ci sono più turni programmabili per questa settimana</div>
            </div>
        </div>
        <div class="all-sales-points">
            <div class="heading-container">
                <div class="title">
                    Tutti i punti vendita
                </div>
                <FormSelect v-if="weekChoice" :disabled="disabled" labelText="Gruppo*" :worker-select="true" :item-list="groupsList" v-model:value="groupId" />
            </div>
            <CreateList :week-choice="weekChoice" :selectAllDisabled="disabled" :item-list="allSalesPoints" :shift-shops="shopsList" :scheduleId="scheduleId" @checked="checked" />
        </div>
        <Button text="Continua creazione" icon="arrow" buttonType="brandFull" :rounded="true" :disabled="(create) ? false : true"
            @click="(create) ? $emit('createShift', weekNr, year, groupId) : ''" />
    </div>
</template>

<script setup>

import CreateList from '../shifts/CreateList.vue';
import FormSelect from '../tools/FormSelect.vue';
import Button from '../tools/Button.vue';
import ScheduleService from '../../services/scheduleService';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayOfYear)
require('dayjs/locale/it')
const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekYear = require('dayjs/plugin/weekYear')
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

</script>

<script>

export default {
    name: 'create-shift-form',
    components: {
        CreateList,
        FormSelect,
        Button
    },
    props: {
        shopsList: {
            default: null,
            type: Array
        },
        weekChoice: {
            default: true,
            type: Boolean
        },
        scheduleId: {
            default: null,
            type: Number
        },
        group_id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            // local: false,
            allSalesPoints: [],
            create: false,
            disabled: true,
            filters: true,
            groupId: (this.group_id !== null) ? this.group_id : null,
            groupsList: [],
            lastSalesPoints: [],
            isWeekSelected: false,
            selectedShops: [],
            week: null,
            weekError: false,
            weekNr: null,
            weeksList: [],
            year: null,
        }
    },
    methods: {
        createWeeksList() {
            this.weeksList = ScheduleService.createAvailableWeeksList()
        },
        checked(selectedShop) {
            this.selectedShops = {...selectedShop}
        }
    },
    emits: ['createShift'],
    computed: {
        groupType() {
            if (this.isLocal) {
                return true
            } else {
                return false
            }
        },
        weekObject() {
            return JSON.parse(this.week)
        }
    },
    watch: {
        async week(value) {
            if (value !== null && value !== '') {
                this.isWeekSelected = true
                this.groupsList = []
            }
            var week = JSON.parse(value)

            this.weekNr = week.weekNr
            this.year = week.year

            const allGroups = JSON.parse(localStorage.getItem('groupsList'))

            var availableGroups = await ScheduleService.getAvailableGroups(
                localStorage.getItem('token'),
                week.year,
                week.weekNr
            )

            if (availableGroups !== false) {

                this.weekError = false
                this.disabled = false

                Array.from(availableGroups).map((groupId) => {
                    Array.from(allGroups).map((group) => {
                        if (groupId == group.id && group.status == 'attivo') {
                            this.groupsList.push({
                                id: groupId,
                                group_name: group.group_name
                            })
                        }
                    })
                })

                if(this.groupsList.length == 0){
                    this.weekError = true
                    this.disabled = true
                }
            } else {
                this.groupsList = []
                Array.from(allGroups).map((group) => {
                    if (group.status == 'attivo') {
                        this.groupsList.push({
                            id: group.id,
                            group_name: group.group_name
                        })
                    }
                })
                this.weekError = false
                this.disabled = false
            }
        },
        groupId(value) {
            const shopsList = JSON.parse(localStorage.getItem('shopsList'))
            this.allSalesPoints = []
            if (value !== null) {
                Array.from(shopsList).map((shop) => {
                    if (shop.group_id == value && shop.status == 'attivo') {
                        this.allSalesPoints.push(shop)
                    }
                })
            }
        },
        shopsList(value){
            console.log(value)
            if(value.length > 0){
                this.create = true
            }else{
                this.create = false
            }
        },
        selectedShops(value){
            console.log(Object.keys(value))
            if(Object.keys(value).length > 0){
                this.create = true
            }else{
                this.create = false
            }
        }
    },
    mounted() {
        var salesPoints = JSON.parse(localStorage.getItem('shopsList'))
        var groups = JSON.parse(localStorage.getItem('groupsList'))
        var activeGroups = []

        this.allSalesPoints = []
        if (this.group_id !== null) {
            Array.from(salesPoints).map((shop) => {
                if (shop.group_id == this.group_id && shop.status == 'attivo') {
                    this.allSalesPoints.push(shop)
                }
            })
        }

        Array.from(groups).map((group) => {
            if(group.status == 'attivo'){
                activeGroups.push(group)
            }
        })

        this.groupsList = activeGroups
        this.createWeeksList()

        if(this.shopsList !== null){
            if(this.shopsList.length > 0){
                this.create = true
            }
        }

        console.log(this.selectedShops)
    }
}

</script>