<template>
    <div class="main">
        <PageTitle @back="back"/>
        <div class="window">
            <FormSelect v-if="!visible && !edit" class="status-select" labelText="Filtra" :item-list="statusList" v-model:value="status" />
            <InspectorDetail v-if="visible || edit" :options="worker" :listEdit="edit" :notes="notes" />
            <List v-if="!visible && !edit" :item-list="inspectorList" @edit="workerEdit" @delete="workerDelete" @archive="workerArchive" @visible="workerVisible" @active="active"/>
            <ProfileRemover v-if="erase" :profile="worker" text="Elimina operatore" toDelete="l'operatore" @cancel="profileDelete"/>
        </div>
        <Modal v-if="archive" title="Ispettore archiviato" text="Ispettore archiviato con successo" @redirect="redirect"/>
        <Modal v-if="reactivate" title="Ispettore attivo" text="Ispettore riattivato con successo" @redirect="redirect"/>
        <Modal v-if="error" title="Qualcosa è andato storto" text="Ricontrollare i dati o riprovare più tardi" @redirect="redirect" />
    </div>
</template>

<script setup>

import PageTitle from '../../components/PageTitle.vue';
import InspectorDetail from '../../components/InspectorDetail.vue';
import List from '../../components/employees/inspectors/List.vue';
import ProfileRemover from '../../components/tools/ProfileRemover.vue';
import WorkerService from '../../services/workerService';
import { setWorker } from '../../setters'
import FormSelect from '../../components/tools/FormSelect.vue';
import Modal from '../../components/tools/Modal.vue';

</script>

<script>

export default {
    name : 'inspectors-list',
    components : {
        PageTitle,
        InspectorDetail,
        List,
        ProfileRemover,
        FormSelect,
        Modal
    },
    data(){
        return{
            archive : false,
            delete : false,
            edit : false,
            erase : false,
            error: false,
            inspectorList : [],
            notes: [],
            reactivate : false,
            status: 'attivo',
            visible : false,
            worker : null,
        }
    },
    methods : {
        workerDelete(bool){
            this.delete = bool
            this.erase = true
        },
        async workerEdit(bool, item, notes){

            this.edit = bool
            this.worker = item
            this.notes = await notes

            document.querySelector('.page-title').innerHTML = 'Dettaglio ispettore'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        async workerArchive(bool, item){

            this.worker = item

            var workerData = setWorker(this.options, 'archiviato', null, 'ispettore')

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.worker.id)

            if(Worker !== false){

                this.archive = bool

                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {

                    if(this.worker.id == worker.id){
                        worker.status = 'archiviato'
                    }
                })

                localStorage.setItem('workersList', JSON.stringify(workersList))
            }else{
                this.error = true
            }

        },
        async active(bool, item){

            this.worker = item

            var workerData = setWorker(this.options, 'attivo', null, 'ispettore')

            const Worker = await WorkerService.updateWorker(localStorage.getItem('token'), workerData, this.worker.id)

            if(Worker !== false){
                
                this.reactivate = bool

                var workersList = JSON.parse(localStorage.getItem('workersList'))

                Array.from(workersList).map((worker) => {

                    if(this.worker.id == worker.id){
                        worker.status = 'attivo'
                    }
                })

                localStorage.setItem('workersList', JSON.stringify(workersList))
            }else{
                this.error = true
            }
            
        },
        async workerVisible(bool, item, notes){

            this.visible = bool
            this.worker = item
            this.notes = await notes

            document.querySelector('.page-title').innerHTML = 'Dettaglio ispettore'
            document.querySelector('.back-arrow').style.visibility = 'visible'
        },
        profileDelete(bool){
            this.erase = bool
        },
        back(bool){

            this.edit = bool
            this.visible = bool
            document.querySelector('.page-title').innerHTML = 'Elenco ispettori'
            document.querySelector('.back-arrow').style.visibility = 'hidden'
        },
        async getWorkers() {

            var inspectorList = await WorkerService.getWorkers(localStorage.getItem('token'))

            Array.from(inspectorList).map((worker) => {

                if(worker.role == 'ispettore'){
                    this.inspectorList.push(worker)
                }
            })

            return this.inspectorList
        },
        redirect(){
            this.archive = false
            this.error = false
            this.$router.go()
        }
    },
    watch : {
        status(value){
            
            var list = JSON.parse(localStorage.getItem('workersList'))
            var filteredList = []

            Array.from(list).map((item) => {

                if(item.status == value && item.role == 'ispettore'){
                    filteredList.push(item)
                }
            })

            if(value !== 'tutti'){

                this.inspectorList = filteredList
            }else{

                var actualList = []

                Array.from(list).map((worker) => {

                    if(worker.role == 'ispettore'){
                        actualList.push(worker)
                    }
                })

                this.inspectorList = actualList
            }
        }
    },
    computed : {
        statusList(){
            return [
                {full_name : 'Attivi', status : 'attivo'},
                {full_name : 'Archiviati', status : 'archiviato'},
                {full_name : 'Tutti', status : 'tutti'},
            ]
        }
    },
    async mounted(){

        var list = await this.getWorkers()
        var filteredList = []

        Array.from(list).map((item) => {

            if(item.status == this.status && item.role == 'ispettore'){
                filteredList.push(item)
            }
        })

        if(this.status !== 'tutti'){
            this.inspectorList = filteredList
        }else{

            this.inspectorList = list
        }
    }
}

</script>