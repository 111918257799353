<template>
<div class="page-title-container">
    <div ref="backArrow" class="back-arrow" @click="$emit('back', false)">
        <svg class="icon icon-arrow">
            <use xlink:href="#arrow-back"></use>
        </svg>
    </div>
    <div ref="pageTitle" class="page-title">
        <div class="title">
            {{ $route.name }}
        </div>
    </div>
    <div v-if="dashboard" class="dashboard-icons">
            <Button text="Punti vendita" buttonType="brand" icon="shopping-cart" :rounded="true" :disabled="(shops) ? true : false" @click="$emit('shops'), shops = true" />
            <Button text="Operatori" buttonType="brand" icon="account-circle" :rounded="true" :disabled="(!shops) ? true : false" @click="$emit('workers'), shops = false" />
            <div class="panoramic" @click="fullscreen(), $emit('panoramic', panoramic)">
                <svg class="icon icon-arrow">
                    <use :xlink:href="icon"></use>
                </svg>
            </div>
        </div>
</div>
</template>

<script setup>

import Button from './tools/Button.vue';

</script>

<script>

export default {
    name : 'page-title',
    edits : ['back', 'panoramic', 'workers', 'shops'],
    components : {
    Button,
    },
    data(){
        return{
            icon : '#fullscreen',
            panoramic : false,
            shops : true
        }
    },
    props: {
        dashboard : {
            default: false,
            type: Boolean
        }
    },
    methods : {
        fullscreen(){
            var scheduler = document.querySelectorAll('.scheduler');
            var window = document.querySelector('.window');

            Array.from(scheduler).map((scheduler) => {
                if(scheduler.classList.contains('panoramic')){

                scheduler.classList.remove('panoramic');
                window.classList.remove('no-border');
                this.panoramic = false
                }else{

                scheduler.classList.add('panoramic');
                window.classList.add('no-border');
                this.panoramic = true
                }
            })
        }
    },
    watch : {
        panoramic(){
            if(this.panoramic == false){
                this.icon = '#fullscreen'
            }else{
                this.icon = '#fullscreen-exit'
            }
        }
    }
}

</script>